import React from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import { Container, Form, Button, Table } from 'react-bootstrap';

const MyInventory = (props) => {
  const { uid } = props.auth.user;
  const chronologies = props.chronologies;
  const chrons = chronologies && Object.keys(chronologies).map((k)=>{
    return chronologies[k].filter((chron)=>chron.empId==uid)
  }).filter((arr)=>arr.length>0).flat()
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <h3 className='grey-text text-darken-3'>Inventory Details</h3>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
            </tr>
          </thead>
          <tbody>
            {chrons && chrons.length > 0 && chrons.map((chron,i)=>{
              return(
                <tr key={i}>
                  <td>{chron.chronId}</td>
                  <td>{props.inventories[chron.chronId].name}</td>
                  <td>{chron.start}</td>
                  <td>{chron.end}</td>
                </tr>
              );
            })
            }
          </tbody>
        </Table>
      </Container>
      <Container>
        <Button className='m-1' variant="secondary" onClick={()=> navigate('/')}>
          Back
        </Button>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(MyInventory);
