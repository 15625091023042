import React from "react";
import DeleteLeave from './DeleteLeave'
import ApproveLeave from './ApproveLeave'


const LeaveSummary = ({category, leaveId, leave, removeLeave, approveLeave, uid}) => {
  return(
    <tr className="align-middle">
      <td>{leave.begin}</td>
      <td>{leave.end}</td>
      <td>{leave.duration}</td>
      <td>{leave.month}</td>
      <td>{leave.reason}</td>
      <td>
        {category==='ADMIN' && !leave.approved ? <ApproveLeave uid={uid} leaveId={leaveId} leave={leave} approveLeave={approveLeave} /> : null}
        {category==='ADMIN' || !leave.approved ? <DeleteLeave leaveId={leaveId} leave={leave} removeLeave={removeLeave} uid={uid}/> : null}
      </td>
    </tr>
  );
}

export default LeaveSummary;