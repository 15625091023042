import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';

const ApproveLeave = ({ uid, leave, leaveId, approveLeave, employees }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [month,setMonth] = useState('');
  return(
    <>
      <span className="btn btn-primary m-1 rounded-circle modal-trigger" onClick={handleShow}>
        <i className="bi bi-check"></i>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Approve Leave?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Employee: {employees[uid].firstName} {employees[uid].lastName}</p>
          <p>From: {leave.begin} To: {leave.end}</p>
          <p>Duration: {leave.duration}</p>
          <p>Reason: {leave.reason}</p>
          <Form.Group as={Row} className="mb-3" controlId="month"> 
            <Form.Label className='fw-bold' column sm="2">Month: </Form.Label>
            <Col sm="5">
              <Form.Control required type="month" onChange={(e)=>setMonth(e.target.value)} value={month}/>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={()=> {approveLeave(uid,leaveId,month)}}>
            Approve
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(ApproveLeave);