import React from "react";
import { Container, Table } from "react-bootstrap";
import { connect } from "react-redux";

const DeletedFiles = (props) => {
  const items = props.lms.deletedFiles || []
  return(
    <Container className='p-2'>
      <Table striped bordered hover responsive id="registrantTable">
        <thead>
          <tr className="allign-middle">
            <th>File Locations</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.length === 0 ? <tr><td colSpan={3}>'No Records Available'</td></tr>: ''}
          {items && items.map(id => {
            return(
              <tr key={id}>
                <td>{id}</td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return state;
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     removeRegistrant: (registrant) => dispatch(removeRegistrant(registrant)),
//   }
// }

export default connect(mapStateToProps)(DeletedFiles);