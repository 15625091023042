import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { listOfCurrentFiles, listOfNewFiles, listOfDeletedFiles } from "../../store/actions/lmsActions";
import { Tabs, Tab, Container, Row, Col } from "react-bootstrap";
import CurrentFiles from "./library/CurrentFiles";
import NewFiles from "./library/NewFiles";
import DeletedFiles from "./library/DeletedFiles";

const LmsLibrary = (props) => {
  useEffect(()=>{
    props.listOfCurrentFiles();
    props.listOfDeletedFiles();
    props.listOfNewFiles();
  },[props.lms.currentFiles,props.lms.deletedFiles,props.lms.newFiles])

  return(
    <Container className='p-2'>
      <Row>
        <Col>
          <h2> FILES  </h2>
        </Col>
      </Row>
      <Tabs defaultActiveKey='active' id='files' className='m-2' justify>
        <Tab eventKey='active' title='Active'>
          <CurrentFiles />
        </Tab>
        <Tab eventKey='new' title='New'>
          <NewFiles />
        </Tab>
        <Tab eventKey='deleted' title='Deleted'>
          <DeletedFiles />
        </Tab>
      </Tabs>
    </Container>
  );
}

const mapStateToProps = (state)=>{
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    listOfCurrentFiles: () => dispatch(listOfCurrentFiles()),
    listOfDeletedFiles: () => dispatch(listOfDeletedFiles()),
    listOfNewFiles: () => dispatch(listOfNewFiles())
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(LmsLibrary);