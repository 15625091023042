import { Card } from 'react-bootstrap';

const CardIcon = (props) => {
  return(
    <Card style={{ width:'7rem',padding: '1px', borderWidth: '0px'}} className='m-1'>
      <center>
        <svg xmlns="http://www.w3.org/2000/svg" width="4.5rem" height="4.5rem" viewBox="0 0 16 16">
          {props.children}
        </svg>
        <h4 className='m-1' style={{fontFamily:'Open Sans', color:'black', fontSize:'15px' }}>{props.title}</h4>
      </center>
    </Card>);
}

export default CardIcon;