import React from "react";
import { NavLink } from "react-router-dom";
import DeleteRegistrant from './DeleteRegistrant'


const RegistrantSummary = ({registrant, removeRegistrant}) => {
  return(
    <tr className="align-middle">
      <td>{registrant.firstName} {registrant.lastName}</td> 
      <td>{registrant.email}</td>
      <td className="d-none d-lg-table-cell">{registrant.entity}</td>
      <td className="d-none d-lg-table-cell">{registrant.designation}</td>
      <td>
        <NavLink className="btn btn-primary m-1 rounded-circle" to={`/registrant/approve/${registrant.id}`}>
          <i className="bi bi-check-lg"></i>
        </NavLink>
        <DeleteRegistrant registrant={registrant} removeRegistrant={removeRegistrant}/>
      </td>
    </tr>
  );
}

export default RegistrantSummary;