import React from "react";
import InventorySummary from "./InventorySummary";
import { connect } from 'react-redux'
import { removeInventory } from "../../store/actions/inventoryActions";
import { Table, Container, Row, Col, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const InventoryList = (props) => {
  const keys = props.inventories ? Object.keys(props.inventories) : []
  return(
    <Container className='p-2'>
      <Row>
        <Col>
          <h2> INVENTORIES </h2>
        </Col>
        <Col className="m-1 d-flex flex-row-reverse">
          <NavLink to='/inventory/create'>
            <Button variant="primary">
              Add Inventory 
            </Button>
          </NavLink>
        </Col>
      </Row>
      <Table striped bordered hover responsive id="inventoryTable">
        <thead>
          <tr className="allign-middle">
            <th>Inventory ID</th>
            <th>Inventory Name</th>
            <th className="d-none d-lg-table-cell">Tags</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? <tr><td colSpan={3}>'No Records Available'</td></tr>: ''}
          {keys && keys.map(invId => {
            return(
              <InventorySummary key={invId} inventory={props.inventories[invId]} removeInventory={props.removeInventory}/>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}


const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeInventory: (inventory) => dispatch(removeInventory(inventory)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(InventoryList);