import { EmailAuthProvider, signInWithCredential } from "firebase/auth"
import { auth2, functions2 } from "../../config/fbConfig"
import { httpsCallable } from "firebase/functions";

export const generateQR = (email,pass) => {
  const cred = EmailAuthProvider.credential(email,pass)
  return(dispatch,getState) => {
    const generateQRFunction = httpsCallable(functions2,'generateQRFunction')
    signInWithCredential(auth2,cred).then((obj)=>{
      generateQRFunction().then((result)=>{
        if(result.data.url){
          dispatch({type: 'DISPLAY_QR', dataUrl: result.data.url})
        } else {
          dispatch({type: 'ALREADY_CONFIGURED', error: result.data.error})
        }
      }).catch((error)=>{
        dispatch({type: 'ALREADY_CONFIGURED', error: error.message})
      })
    }).catch(error => {
      dispatch({type: 'ALREADY_CONFIGURED', error: error.message})
    })
  }
}

export const clearError = () => {
  return (dispatch,getState) => {
    dispatch({type:'ALREADY_CONFIGURED',error:''})
  }
}
