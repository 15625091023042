import { auth, functions } from "../../config/fbConfig";
import { signOut as so, onAuthStateChanged, EmailAuthProvider, reauthenticateWithCredential, updatePassword, signInWithCustomToken } from 'firebase/auth'
import { httpsCallable } from "firebase/functions";

export const signIn = (credentials) => {
  const loginFunction = httpsCallable(functions,'loginFunction')
  return (dispatch, getState) => {
    loginFunction({email: credentials.email,pwd:credentials.password, totp:credentials.totp}).then((result)=>{
      if(result.data.token){
        signInWithCustomToken(auth, result.data.token).then((obj)=>{
          auth.currentUser.getIdTokenResult().then((idTokenResult)=>{
            dispatch({type: 'LOGIN_SUCCESS', user: obj.user, category:idTokenResult.claims.category, groups:idTokenResult.claims.groups})
          })
        }).catch((err) => {
          dispatch({type: 'LOGIN_ERROR', err: 'Invalid Credentials'})
        })
      } else { 
        dispatch({type: 'LOGIN_ERROR', err: result.data.error})
      }
    })
  }
}

export const reset = (email) => {
  const resetPassword = httpsCallable(functions,'resetPassword')
  return(dispatch, getState) => {
    resetPassword({email}).then(()=>{
      dispatch({type: 'RESET_SUCCESS'})
    }).catch((err)=>{
      dispatch({type: 'RESET_ERROR',err: err.message})
    })
  }
}

export const signOut = () => {
  return (dispatch, getState) => {
    so(auth).then(() => {
      dispatch({type:'SIGNOUT_SUCCESS'});
      dispatch({type:'CLEAR_EMPLOYEES'});
    })
  }
}

export const stateChange  = () => {
  return (dispatch, getState) => {
    onAuthStateChanged(auth, user => {
      if(user) {
        auth.currentUser.getIdTokenResult().then((idTokenResult)=>{
          dispatch({type: 'LOGIN_SUCCESS', user , category:idTokenResult.claims.category, groups:idTokenResult.claims.groups})
        })
      } else {
        dispatch({type:'SIGNOUT_SUCCESS'});
      }
    })
  }
}

export const updateUserPassword = (password) => {
  const cred = EmailAuthProvider.credential(auth.currentUser.email,password.old)
  return(dispatch, getState) => {
    reauthenticateWithCredential(auth.currentUser,cred).then((cred)=>{
      updatePassword(auth.currentUser,password.new)
      so(auth)
    }).catch((err)=>{
      dispatch({type:'PASSWORD_UPDATE_FAILED',err})
    })
  }
}

export const clearError = () => {
  return (dispatch,getState) => {
    dispatch({type:'LOGIN_ERROR',err:''})
  }
}