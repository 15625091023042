import React from "react";
import InviteeSummary from "./InviteeSummary";
import { connect } from 'react-redux'
import { removeInvitee } from "../../../store/actions/inviteeActions";
import { Table, Container } from "react-bootstrap";

const InviteeList = (props) => {
  const keys = props.invitees ? Object.keys(props.invitees) : []
  return(
    <Container className='p-2'>
      <Table striped bordered hover responsive id="inviteesTable">
        <thead>
          <tr className="allign-middle">
            <th>Employee Name</th>
            <th>Email ID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? <tr><td colSpan={3}>'No Records Available'</td></tr>: ''}
          {keys && keys.map(inviteeId => {
            return(
              <InviteeSummary key={inviteeId} invitee={props.invitees[inviteeId]} removeInvitee={props.removeInvitee}/>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}


const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeInvitee: (invitee) => dispatch(removeInvitee(invitee)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(InviteeList);