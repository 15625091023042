import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { Container, Form, Row, Col, Button, Table } from 'react-bootstrap';

const SpecTable = (props) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(props.specs).map((k,i)=>{
          return(<tr key={i}>
            <td>{k}</td>
            <td>{props.specs[k]}</td>
          </tr>)
        })}
      </tbody>
    </Table>
  );
}

const InventoryDetails = (props) => {
  const { id } = useParams();
  const inventory = useSelector((state) => state.inventories[id])
  const navigate = useNavigate();
  if(inventory){
    return (
      <Container>
        <Form>
        <h3 className='grey-text text-darken-3'>Inventory Details</h3>
        <Form.Group as={Row} className="mb-3" controlId="firstName">
          <Form.Label className='fw-bold' column sm="2">Inventory ID</Form.Label>
          <Col sm="10">
            <Form.Control plaintext readOnly defaultValue={inventory.invId} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="lastName">
          <Form.Label className='fw-bold' column sm="2">Name</Form.Label>
          <Col sm="10">
            <Form.Control plaintext readOnly value={inventory.name}/>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="dob">
          <Form.Label className='fw-bold' column sm="2">Tags</Form.Label>
          <Col sm="10">
            <Form.Control plaintext readOnly value={inventory.tags && inventory.tags.map(({value})=>value).toString()}/>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="dob">
          <Form.Label className='fw-bold' column sm="2">Specifications</Form.Label>
          <Col sm="10">
            {inventory.specifications ? <SpecTable specs={inventory.specifications}/> : 'No Specification'}
          </Col>
        </Form.Group>
        <Button variant="secondary" onClick={()=> navigate('/inventories')}>
          BACK
        </Button>
        </Form>
      </Container>
    )
  } else {
    return(
      <div className='container'>
        Loading....
      </div>
    )
  }
}

export default InventoryDetails;
