import React from "react";
import AdminLinks from './AdminLinks'
import { connect } from "react-redux";
import { Container, Navbar, Nav, NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import BusinessLinks from "./BusinessLinks";
import EmployeeLinks from "./EmployeeLinks";
import { signOut } from "../../store/actions/authActions";
import { useNavigate } from "react-router-dom";
import ToastMessage from "../toasts/ToastMessage";

const NavigationBar = (props) => {
  const { category,user } = props.auth;
  const navigate = useNavigate()
  const links = (category==='ADMIN') ? <AdminLinks /> : (category==='INVITEE') ? null : (category==='BUSINESS') ? <BusinessLinks /> : (category==='EMPLOYEE') ? <EmployeeLinks /> : null
  return(
    <Navbar bg='dark' variant="dark" expand='lg' sticky="top">
      <ToastMessage />
      <Container>
        <Navbar.Brand>
          My-Employee
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse className="justify-content-end ms-auto" id="basic-navbar-nav">
          <Nav>
            {links}
            {category && category!=='LOGIN' ?
              <NavDropdown className="dropdown-menu-start me-5 pe-5"
                  title={<OverlayTrigger placement="bottom" overlay={<Tooltip className="m-2" id="tooltip">{user.email}</Tooltip>}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                          </svg>
                        </OverlayTrigger>}
                  id="basic-nav-dropdown">
              <NavDropdown.Item onClick={()=>navigate('/myprofile')}>
                <i className="bi bi-person-circle" /> Profile
              </NavDropdown.Item>
              <NavDropdown.Item onClick={props.signOut}>
                <i className="bi bi-box-arrow-in-right" /> Logout
              </NavDropdown.Item>
              </NavDropdown> : null 
            }
          </Nav> 
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(NavigationBar);