import React from "react";
import LeaveSummary from "./LeaveSummary";
import { connect } from 'react-redux'
import { removeLeave, approveLeave } from "../../store/actions/leaveActions";
import { Table, Container } from "react-bootstrap";

const LeaveList = (props) => {
  const { category } = props.auth;
  const uid = category==='ADMIN' ? props.uid : props.auth.user.uid;
  const keys = props.filterLeaves || []
  let sum = 0;
  return(
    <Container className='p-2'>
      <Table striped bordered hover responsive id="leavesTable">
        <thead>
          <tr className="allign-middle">
            <th>Begin</th>
            <th>End</th>
            <th>Duration</th>
            <th>Month</th>
            <th>Reason</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? <tr><td colSpan={6}>'No Records Available'</td></tr>: ''}
          {keys && keys.map(leaveId => {
            sum += parseFloat(props.leaves[uid][leaveId].duration)
            return(
              <LeaveSummary key={leaveId} uid={uid} category={category} leaveId={leaveId} leave={props.leaves[uid][leaveId]} removeLeave={props.removeLeave} approveLeave={props.approveLeave}/>
            );
          })}
          {keys.length !== 0 ? 
          <tr>
            <td colSpan={2}>Total</td>
            <td>{sum}</td>
          </tr> : null }
        </tbody>
      </Table>
    </Container>
  );
}


const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeLeave: (uid,leaveId) => dispatch(removeLeave(uid,leaveId)),
    approveLeave: (uid,leaveId,month) => dispatch(approveLeave(uid,leaveId,month))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LeaveList);