import { db } from '../../config/fbConfig'
import { ref, child, set, onValue, remove } from  'firebase/database'

const groupsRef = child(ref(db),'groups')
export const createGroup = (group) => {
  const groupRef = child(groupsRef,group.id);
  return (dispatch, getState) => {
    set(groupRef,{
      ...group
    }).then(()=>{
      dispatch({ type: 'CREATE_GROUP', group});
    }).catch((err) => {
      dispatch({ type: 'CREATE_GROUP_ERROR', err });
    })
  }
}

export const fetchGroups = () => {
  return (dispatch, getState) => {
    onValue(groupsRef , (snapshot)=>{
      const groups = snapshot.val();
      dispatch({ type: 'GET_GROUPS', groups })
    })
  }
}

export const removeGroup = (group) => {
  const exGroupsRef = child(child(ref(db),'exGroups'),group.id)
  return (dispatch, getState) => {
    const removeRef = child(groupsRef,group.id);
    remove(removeRef).then(()=> {
      dispatch({type: 'REMOVE_GROUP', group})
    }).catch((err) => {
      dispatch({ type: 'REMOVE_GROUP_ERROR', err })
    })
    set(exGroupsRef,group);
  }
}