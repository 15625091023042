import { getDownloadURL, ref as refs } from "firebase/storage"
import { storage, db } from "../../config/fbConfig"
import { ref , child, set, onValue } from  'firebase/database'

const courseRef = child(ref(db),'course')
export const getVideoURLs = (videos) => {
    return (dispatch,getState) => {
        Object.entries(videos).forEach(([index,[title,name,category]]) => {
            name ? getDownloadURL(refs(storage,'videos/'+name)).then(url => {
                dispatch({type: 'VIDEO_URLS', urls: {...getState().lms.urls, [index]:[title,url,category]}})
            }) : dispatch({type: 'VIDEO_URLS', urls: {...getState().lms.urls, [index]:[title,null,category]}})
        })
    }
}

export const setStatus = (uid,status) => {
    const employeeRef = child(courseRef,uid);
    return (dispatch,getState) => {
        set(child(employeeRef,'status'),{...status}).then(()=>{
            dispatch({type:'SET_STATUS',status})
        }).catch((err) => {
            dispatch({ type: 'SET_STATUS_ERROR', err });
          })
    }
}

export const getStatus = (uid) => {
    const employeeRef = child(courseRef,uid);
    return (dispatch,getState) => {
        onValue(child(employeeRef,'status'),(ss)=>{
            const status = ss.val();
            dispatch({ type: 'GET_STATUS', status })
        })
    }
}