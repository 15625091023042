import React from "react";
import LeadSummary from "./LeadSummary";
import { connect } from 'react-redux'
import { removeLead } from "../../store/actions/leadActions";
import { Table, Container } from "react-bootstrap";

const EmployeeList = (props) => {
  const keys = props.leads ? Object.keys(props.leads) : []
  return(
    <Container className='p-2'>
      <Table striped bordered hover responsive id="employeeTable">
        <thead>
          <tr className="allign-middle">
            <th>Org Name</th>
            <th>Lead Name</th>
            <th>Context</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? <tr><td colSpan={3}>'No Records Available'</td></tr>: ''}
          {keys && keys.map(id => {
            return(
              <LeadSummary key={id} id={id} event={props.event} lead={props.leads[id]} removeLead={props.removeLead}/>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}


const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeLead: (event,id) => dispatch(removeLead(event,id)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(EmployeeList);