const initialState={}

export const leadReducer = (state=initialState, action) => {
  switch(action.type){
    case 'LEAD_ERROR':
      return {
        ...state,
        leadError: action.err.message,
      }
    case 'FETCH_EVENTS':
      return {
        ...state,
        events: action.events
      } 
    case 'GET_LEADS':
      return action.leads;
    case 'REMOVE_LEAD':
      return state;
    case 'CLEAR_LEAD':
      return {};
    default:
      break;
  }
  return state;
}

export default leadReducer;