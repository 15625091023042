import { Button, Form } from "react-bootstrap";
import { useState } from "react";

const Question = (props) => {
    const [selectedOption, setSelectedOption] = useState('');
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if(selectedOption===props.question[2]){
            props.increaseScore()
        }
        props.nextQuestion();
    }

    return(
        <Form onSubmit={handleSubmit}>
            <h4>{props.question[0]}</h4>
            {props.question[1].map(option=>{
                return(
                    <Form.Check
                    key={option} 
                    type="radio"
                    label={option}
                    name={option}
                    value={option}
                    checked={selectedOption === option}
                    onChange={handleOptionChange}
                    className="custom-radio"
                    />
                )
            })}
            <Button type="submit">Submit</Button>
        </Form>
    )
}

export default Question;