import { connect } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireApprove = (props) => {
  const { category } = props.auth;
  const location = useLocation();
  if(category==='INVITEE') {
    return <Navigate to='/registrant/create' state={{from: location}} />
  }
  return <Outlet />
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(RequireApprove);