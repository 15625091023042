import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchGroups, createGroup } from '../../store/actions/groupActions'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select';
import { getLibrary } from '../../store/actions/lmsActions';

const EditGroup = (props) => {
  const navigate = useNavigate();
  const { id }  = useParams();
  const [group,setGroup] = useState(props.groups[id])
  useEffect(()=>{
    setGroup(props.groups[id])
  },[props.groups])
  useEffect(()=>{
    props.getLibrary();
    props.fetchGroups();
  },[])

  const employeeOptions = Object.keys(props.employees).map((empId)=>{
    return {value:empId, label: props.employees[empId].firstName + props.employees[empId].lastName}
  })

  const videoOptions = props.lms.library && Object.keys(props.lms.library).map((key)=>{
    return {value:key, label: props.lms.library[key]}
  })

  const handleChange = (e) => {
    setGroup({ ...group,
      [e.target.id]: e.target.value
    })
  }

  const employeeUpdated = (val) => {
    setGroup({ ...group,
      employees : val
    })
  }

  const videoUpdated = (val) => {
    setGroup({ ...group,
      videos : val
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    props.createGroup(group)
    navigate('/groups')
  }

if(!group) {
    return(
      <h3>loading...</h3>
    )
  } else {
    return (
      <Container>
        <Form onSubmit={handleSubmit}>
          <h3 className='grey-text text-darken-3'>Edit Group</h3>
          <Form.Group as={Row} className="mb-3" controlId="id">
            <Form.Label className='fw-bold' column sm="2">Inventory ID</Form.Label>
            <Col sm="10">
              <Form.Control plaintext readOnly onChange={handleChange} value={group.id}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="name">
            <Form.Label className='fw-bold' column sm="2">
              Name
            </Form.Label>
            <Col sm="10">
              <Form.Control required onChange={handleChange} value={group.name} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="employees">
            <Form.Label className='fw-bold' column sm="2">
              Employees
            </Form.Label>
            <Col sm="10">
              <Select isMulti value={group.employees} onChange={employeeUpdated} options={employeeOptions} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="videos">
            <Form.Label className='fw-bold' column sm="2">
              Videos
            </Form.Label>
            <Col sm="10">
              <Select isMulti value={group.videos} onChange={videoUpdated} options={videoOptions} />
            </Col>
          </Form.Group>
          <Form.Group>
          <Button className='m-1' variant="warning" type="submit">
            UPDATE
          </Button>
          <Button className='m-1' variant="secondary" onClick={()=> navigate('/groups')}>
            CANCEL
          </Button>
        </Form.Group>
        </Form>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroups: () => dispatch(fetchGroups()),
    createGroup: (group) => dispatch(createGroup(group)),
    getLibrary: () => dispatch(getLibrary())
  }
}

const mapStateToProps = (state) => {
  return state
} 

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);
