import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createInvitee } from '../../../store/actions/inviteeActions'
import { Container, Form, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'

const CreateInvitee = (props) => {
  const [disabled,setDisabled] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false);reset();setDisabled(false)}
  const handleShow = () => setShow(true);
  const [message,setMessage] = useState('');
  const [duplicateUser,setDuplicateUser] = useState({})
  const [invitee,setInvitee] = useState({
    entity: 'Adaptiv Work',
    firstName: '',
    lastName: '',
    email: ''
  })

  useEffect(()=>{
    if (props.employees && Object.keys(props.employees).filter((eid) => props.employees[eid].email===invitee.email.trim()).length){
      setMessage('Employee Already Exist')
      setDuplicateUser(props.employees[Object.keys(props.employees).filter((eid) => props.employees[eid].email===invitee.email.trim())])
    } else if (props.registrants && Object.keys(props.registrants).filter((eid) => props.registrants[eid].email===invitee.email.trim()).length){
      setMessage('Employee Already Registered')
      setDuplicateUser(props.registrants[Object.keys(props.registrants).filter((eid) => props.registrants[eid].email===invitee.email.trim())])
    } else if (props.invitees && Object.keys(props.invitees).filter((id) => props.invitees[id].email===invitee.email.trim()).length){
      setMessage('Employee Already Invited')
      setDuplicateUser(props.invitees[Object.keys(props.invitees).filter((id) => props.invitees[id].email===invitee.email.trim())])
    } else {
      setMessage('')
      setDuplicateUser({})
    }
  },[invitee.email])

  useEffect(()=>{
    if(props.toastMessage && props.toastMessage.message){
    setDisabled(false);
    }
  },[props.toastMessage])

  const handleChange = (e) => {
    setInvitee({ ...invitee,
      [e.target.id]: e.target.value
    })
  }

  const reset = () => {
    setInvitee({
      entity: 'Adaptiv Work',
      firstName: '',
      lastName: '',
      email: ''
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    if(message === ''){
      props.createInvitee(invitee);
      reset();
    } else {
      handleShow();
    }
  }
  
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
      <h3 className='grey-text text-darken-3'>Invite Employee</h3>
      <Form.Group as={Row} className="mb-3" controlId="entity"> 
        <Form.Label className='fw-bold' column sm="2">Entity</Form.Label>
        <Col sm="10">
          <Form.Select onChange={handleChange} value={invitee.entity}>
            <option value="Exgen Systems LLC">Exgen Systems LLC</option>
            <option value="Adaptiv Work">Adaptiv Work</option>
            <option value="Secure Traces">Secure Traces</option>
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="firstName">
        <Form.Label className='fw-bold' column sm="2">
          First Name
        </Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={invitee.firstName} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="lastName">
        <Form.Label className='fw-bold' column sm="2">Last Name</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={invitee.lastName}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="email">
        <Form.Label className='fw-bold' column sm="2">Email</Form.Label>
        <Col sm="10">
          <Form.Control required type='email' onChange={handleChange} value={invitee.email}/>
        </Col>
      </Form.Group> 
      <Form.Group>
        <Button disabled={disabled} className='m-1' variant="success" type="submit">
          {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Invite'}
        </Button>
        <Button className='m-1' variant="warning" onClick={()=> reset()}>
          Reset
        </Button>
        <Button className='m-1' variant="secondary" onClick={()=> navigate('/employees')}>
          Cancel
        </Button>
      </Form.Group>
    </Form>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{message}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Name: {duplicateUser.firstName} {duplicateUser.lastName}</p>
          <p>Email: {duplicateUser.email}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  </Container>
)}

const mapDispatchToProps = (dispatch) => {
  return {
    createInvitee: (invitee) => dispatch(createInvitee(invitee))
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvitee);
