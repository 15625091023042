import React, {useEffect} from "react";
import GroupSummary from "./GroupSummary";
import { connect } from 'react-redux'
import { fetchGroups, removeGroup } from "../../store/actions/groupActions";
import { Table, Container, Row, Col, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const GroupList = (props) => {
  useEffect(()=>{
    props.fetchGroups();
  },[])
  const keys = props.groups ? Object.keys(props.groups) : []
  return(
    <Container className='p-2'>
      <Row>
        <Col>
          <h2> GROUPS </h2>
        </Col>
        <Col className="m-1 d-flex flex-row-reverse">
          <NavLink to='/group/create'>
            <Button variant="primary">
              Add Group 
            </Button>
          </NavLink>
        </Col>
      </Row>
      <Table striped bordered hover responsive id="groupTable">
        <thead>
          <tr className="allign-middle">
            <th>Group ID</th>
            <th>Group Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? <tr><td colSpan={3}>'No Records Available'</td></tr>: ''}
          {keys && keys.map(id => {
            return(
              <GroupSummary key={id} group={props.groups[id]} removeGroup={props.removeGroup}/>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}


const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeGroup: (group) => dispatch(removeGroup(group)),
    fetchGroups: () => dispatch(fetchGroups())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(GroupList);