import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Toast, ToastContainer } from 'react-bootstrap'
import { clearToast } from "../../store/actions/toastActions";

const ToastMessage = (props) => {
  const [show,setShow] = useState(false)
  const [message,setMessage] = useState('')
  const [variant,setVariant] = useState('')
  
  useEffect(()=>{
    if(props.toastMessage && props.toastMessage.message){
    setMessage(props.toastMessage.message)
    setVariant(props.toastMessage.variant)
    setShow(true)
    }
  },[props.toastMessage])

  const closeToast = () => {
    setShow(false);
    props.clearToast();
  }

  return (
    <ToastContainer className="p-3" position="top-center">
      <Toast onClose={closeToast} bg={variant} show={show} delay={3000} autohide>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

const mapStateToProps= (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearToast : () => dispatch(clearToast())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ToastMessage);