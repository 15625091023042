import React from "react";
import { NavLink } from "react-router-dom";
import DeleteEmployee from './DeleteEmployee'


const EmployeeSummary = ({employee, removeEmployee}) => {
  return(
    <tr className="align-middle">
      <td>{employee.empId}</td>
      <td>{employee.firstName} {employee.lastName}</td> 
      <td className="d-none d-lg-table-cell">{employee.email}</td>
      <td className="d-none d-lg-table-cell">{employee.entity}</td>
      <td className="d-none d-lg-table-cell">{employee.designation}</td>
      <td>
        <NavLink className="btn btn-success m-1 rounded-circle" to={`/employee/${employee.id}`}>
          <i className="bi bi-eye"></i>
        </NavLink>
        <NavLink className="btn btn-primary m-1 rounded-circle" to={`/employee/edit/${employee.id}`}>
          <i className="bi bi-pencil"></i>
        </NavLink>
        <DeleteEmployee employee={employee} removeEmployee={removeEmployee}/>
      </td>
    </tr>
  );
}

export default EmployeeSummary;