import React from "react";
import LeaveSummary from "./LeaveSummary";
import { connect } from 'react-redux'
import { removeLeave, approveLeave } from "../../../store/actions/leaveActions";
import { Table, Container } from "react-bootstrap";

const LeaveList = (props) => {
  const uids = (props.leaves && Object.keys(props.leaves)) || []
  return(
    <Container className='p-2'>
      <h3 className='grey-text text-darken-3'>Leaves</h3>
      <Table striped bordered hover responsive id="leavesTable">
        <thead>
          <tr className="allign-middle">
            <th>Employee</th>
            <th>Begin</th>
            <th>End</th>
            <th>Duration</th>
            <th>Month</th>
            <th>Reason</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {uids.length === 0 ? <tr><td colSpan={6}>'No Records Available'</td></tr>: ''}
          {uids && uids.map(uid => {
            return (props.leaves[uid] && Object.keys(props.leaves[uid]).filter(leaveId => !props.leaves[uid][leaveId].approved).map(leaveId => {
              return(
                <LeaveSummary key={leaveId} uid={uid} leaveId={leaveId} leave={props.leaves[uid][leaveId]} removeLeave={props.removeLeave} approveLeave={props.approveLeave} />
              );
            }))
          })}          
        </tbody>
      </Table>
    </Container>
  );
}


const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeLeave: (uid,leaveId) => dispatch(removeLeave(uid,leaveId)),
    approveLeave: (uid,leaveId,month) => dispatch(approveLeave(uid,leaveId,month))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LeaveList);