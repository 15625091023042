import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import NavigationBar from './layout/NavigationBar';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from "./dashboard/Dashboard";
import SignIn from './auth/SignIn';
import { connect } from 'react-redux';
import { fetchRegistrants } from '../store/actions/registrantActions';
import { fetchInventories } from '../store/actions/inventoryActions';
import { fetchTags } from '../store/actions/tagActions';
import { stateChange } from '../store/actions/authActions';
import { fetchChronologies } from '../store/actions/chronActions';
import { fetchInvitees } from "../store/actions/inviteeActions";
import RequireAuth from "./auth/RequireAuth";
import RequireBusiness from "./auth/RequireBusiness";
import CreateRegistrant from './employees/registered/CreateRegistrant';
import ApproveRegistrant from "./employees/registered/ApproveRegistrant";
import EmployeesPage from "./employees/EmployeesPage";
import LeadsPage from "./leads/LeadsPage"
import AddLead from "./leads/AddLead"
import LeadDetails from "./leads/LeadDetails"
import InventoryDetails from './inventories/InventoryDetails';
import CreateInventory from './inventories/CreateInventory';
import EditInventory from "./inventories/EditInventory";
import InventoryList from "./inventories/InventoryList";
import GroupDetails from './groups/GroupDetails';
import CreateGroup from './groups/CreateGroup';
import EditGroup from "./groups/EditGroup";
import GroupList from "./groups/GroupList";
import ChronologyDetails from "./inventories/chronology/ChronologyDetails";
import CreateInvitee from "./employees/invited/CreateInvitee";
import { Container, Spinner } from "react-bootstrap";
import { fetchEmployees, getEmployee } from "../store/actions/employeeActions";
import EmployeeDetails from "./employees/EmployeeDetails";
import EditEmployee from "./employees/EditEmployee";
import EditMyProfile from "./employees/EditMyProfile";
import RequireAdmin from "./auth/RequireAdmin";
import MyInventory from "./inventories/MyInventory";
import MyCalendar from "./calendar/MyCalendar";
import RequireApprove from "./auth/RequireApprove";
import { fetchLeaves, fetchLeavesByUid } from "../store/actions/leaveActions";
import LeaveList from "./calendar/admin/LeaveList";
import Mfa from "./auth/Mfa";
import Reset from "./auth/Reset";
import CoursePage from "./courses/CoursePage";
import HomePage from "./lms/HomePage";
import FileManager from "./lms/FileManager";
import LmsLibrary from "./lms/LmsLibrary";
import EditLead from "./leads/EditLead";
import CourseContent from "./courses/CourseContent";
import { getVideoURLs } from "../store/actions/securityActions";

const App = (props) => {
  const { category, user } = props.auth
  const[loading,setLoading] = useState(true)
  useEffect(() => {
    props.stateChange();
    if(category === 'ADMIN'){
      props.fetchLeaves();
    } else if(category === 'EMPLOYEE' || category === 'BUSINESS'){
      props.getEmployee(user.uid)
      props.fetchLeavesByUid(user.uid)
    }
    props.fetchRegistrants();
    props.fetchInventories();
    props.fetchTags();
    props.fetchChronologies();
    props.fetchInvitees();
    props.getVideoURLs(props.security.videoList);
    if(category){
      setLoading(false)
    }
  },[category])

  if(!loading){
    return(
      <BrowserRouter>
        <div className="App">
          <NavigationBar />
          <Routes>
            <Route path='/signin' element={category && category !== 'LOGIN'? <Navigate to='/' replace /> : <SignIn />} />
            <Route exact path="/mfa" element={category && category !== 'LOGIN'? <Navigate to='/' replace /> : <Mfa />} />
            <Route exact path="/reset" element={category && category !== 'LOGIN'? <Navigate to='/' replace /> : <Reset />} />
            <Route element={<RequireAuth />}>
              <Route exact path='/registrant/create' element={<CreateRegistrant />} />
              <Route element={<RequireApprove />} >
                <Route exact path='/' element={<Dashboard />} />
                <Route exact path="/myprofile" element={<EditMyProfile />} />
                <Route exact path="/myinventory" element={<MyInventory />} />
                <Route exact path="/mycalendar" element={<MyCalendar />} />
                <Route exact path="/my-course" element={<CoursePage />} />
                <Route exact path="/lms/security" element={<CourseContent />} />
                <Route element={<RequireBusiness />} >
                  <Route path='/leads' element={<LeadsPage />} />
                  <Route path='/leads/add' element={<AddLead />} />
                  <Route exact path="/leads/:event/:id" element={<LeadDetails />} />
                  <Route exact path="/leads/edit/:event/:id" element={<EditLead />} />
                </Route>
                <Route element={<RequireAdmin />}>
                  <Route path="/lms" element={<HomePage />} />
                  <Route path="/lms/filemanager" element={<FileManager />} />
                  <Route path="/lms/library" element={<LmsLibrary /> } />
                  <Route path="/leaves" element={<LeaveList />} />
                  <Route path="/employees" element={<EmployeesPage />} />
                  <Route path="/employee/:id" element={<EmployeeDetails />} />
                  <Route path="/employee/edit/:id" element={<EditEmployee />} />
                  <Route exact path="/invite" element={<CreateInvitee />} />
                  <Route path="/registrant/approve/:id" element={<ApproveRegistrant />} />
                  <Route path="/inventories" element={<InventoryList />} />
                  <Route path='/inventory/:id' element={<InventoryDetails />} />
                  <Route path="/inventory/edit/:id" element={<EditInventory />} />
                  <Route exact path='/inventory/create' element={<CreateInventory />} />
                  <Route exact path='/inventory/chronology/:id' element={<ChronologyDetails />} />
                  <Route path="/groups" element={<GroupList />} />
                  <Route path='/group/:id' element={<GroupDetails />} />
                  <Route path="/group/edit/:id" element={<EditGroup />} />
                  <Route exact path='/group/create' element={<CreateGroup />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    );
  } else {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{height: '100%'}}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRegistrants: () => dispatch(fetchRegistrants()),
    stateChange: () => dispatch(stateChange()),
    fetchInventories: () => dispatch(fetchInventories()),
    fetchTags: () => dispatch(fetchTags()),
    fetchChronologies: () => dispatch(fetchChronologies()),
    fetchInvitees: () => dispatch(fetchInvitees()),
    fetchEmployees: () => dispatch(fetchEmployees()),
    fetchLeaves: () => dispatch(fetchLeaves()),
    fetchLeavesByUid: (uid) => dispatch(fetchLeavesByUid(uid)),
    getEmployee: (uid) => dispatch(getEmployee(uid)),
    getVideoURLs: (videos) => dispatch(getVideoURLs(videos)),
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps,mapDispatchToProps)(App);