import React from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

// Define styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
  certificate: {
    fontFamily: 'Helvetica',
    textAlign: 'center',
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
  subTitle: {
    fontSize: 18,
    marginBottom: 10,
  },
  detail: {
    fontSize: 14,
    marginTop: 10,
  },
  backgroundImage: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    top: 0,
    left: 0,
    opacity: 1, // Adjust opacity as needed
  },
});

const Certificate = ({ studentName, courseName, completionDate }) => (
  <Document>
    <Page size="A6" style={styles.page}>
      <Image src="/new03.png" style={styles.backgroundImage} />
      <View style={styles.certificate}>
        <Text style={styles.title}>Certificate of Completion</Text>
        <Text style={styles.subTitle}>This is to certify that</Text>
        <Text style={styles.detail}>{studentName}</Text>
        <Text style={styles.subTitle}>has successfully completed the course:</Text>
        <Text style={styles.detail}>{courseName}</Text>
        <Text style={styles.detail}>on {completionDate}</Text>
      </View>
    </Page>
  </Document>
);

export default Certificate;
