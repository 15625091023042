import React, { useEffect, useState } from "react";
import { Container, Form, Col, Row, Button, Modal, Image, Alert, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { generateQR, clearError } from "../../store/actions/mfaActions";
import { signIn, clearError as clearLoginError } from "../../store/actions/authActions";
import { useNavigate } from "react-router-dom";

const Mfa = (props) => {
  const [error,setError] = useState((props.mfa && props.mfa.error) || '')
  const [loginError,setLoginError]  = useState(props.authError);
  const [disabled,setDisabled] = useState(false);
  const [otpDisabled,setOtpDisabled] = useState(false);
  const [pass,setPass] = useState('')
  const [email,setEmail] = useState('')
  const [totp,setTotp] = useState('')
  const [showQR, setShowQR] = useState(false);
  const handleCloseQR = () => setShowQR(false);
  const handleShowQR = () => setShowQR(true);
  const navigate = useNavigate();

  useEffect(()=>{
    if(props.auth && props.auth.authError){
      setLoginError(props.auth && props.auth.authError);
      setOtpDisabled(false);
    }
  },[props.auth])

  useEffect(()=>{
    if(props.mfa && props.mfa.error){
      setError(props.mfa.error)
      setDisabled(false);
    }
    if(props.mfa && props.mfa.qrImgData){
      handleShowQR();
    }
  },[props.mfa])

  const clearError = (e) => {
    setError('')
    props.clearError();
  }

  const clearLoginError = (e) => {
    setLoginError('')
    props.clearLoginError();
  }

  const totpRegister = (e) => {
    e.preventDefault();
    clearError();
    setDisabled(true);
    props.generateQR(email,pass);
  }

  const handleTotpSubmit = () => {
    clearLoginError();
    setOtpDisabled(true);
    props.signIn({email:email,password:pass,totp:totp})
    setTotp('')
  }

  return(
    <Container>
      <Form onSubmit={totpRegister}>
        <h3 className='grey-text text-darken-3'>Register MFA</h3>
        Download Google Authenticator App.
        <Form.Group as={Row} className="mb-3" controlId="email">
          <Form.Label className='fw-bold' column sm="2">Email</Form.Label>
          <Col sm="10">
            <Form.Control required type='email' onChange={(e)=>setEmail(e.target.value)} value={email} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="pass">
          <Form.Label className='fw-bold' column sm="2">Password</Form.Label>
          <Col sm="10">
            <Form.Control required type='password' onChange={(e)=>setPass(e.target.value)} value={pass} />
          </Col>
        </Form.Group>
        <Form.Group className='mb-3' controlId="errorMessage">
          <Form.Text id='error'>
            {error ? 
              <Alert key='error' variant='danger'>
                <div className='d-flex justify-content-between'>
                  <span>
                    {error}
                  </span>
                  <span onClick={clearError}>
                    X
                  </span>
                </div> 
              </Alert> : null}
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Button disabled={disabled} id="totp-submit" className='m-1' variant="warning" type="submit">
            {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Register'}
          </Button>
          <Button variant="primary" onClick={()=> navigate('/signin')}>
            Sign In
          </Button>
        </Form.Group>
        <Modal show={showQR} onHide={handleCloseQR}>
          <Modal.Header closeButton>
            <Modal.Title>Authenticator QR Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>Scan the QR Code With Google Authenticator App</Row>
            <Row className="justify-content-md-center">
              <Col xs={12} sm={4} md={4}>
                <Image src={props.mfa.qrImgData} width='150px' />
              </Col>
            </Row>
            <Form.Group className='mb-3' controlId="errorMessage">
              <Form.Text id='error'>
                { loginError ? 
                  <Alert key='error' variant='danger'>
                    <div className='d-flex justify-content-between'>
                      <span>
                        {loginError}
                      </span>
                      <span onClick={clearLoginError}>
                        X
                      </span>
                    </div> 
                  </Alert> : null }
              </Form.Text>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="otp">
              <Form.Label className='fw-bold' column sm="2">TOTP</Form.Label>
              <Col sm="10">
                <Form.Control required maxLength={6} onChange={(e)=>setTotp(e.target.value)} value={totp} />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={otpDisabled} variant="secondary" onClick={handleTotpSubmit}>
              {otpDisabled ? <Spinner animation="border" size='sm' role="status" /> : 'Sign In'}
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => {
  return{
    generateQR : (email,pass) => dispatch(generateQR(email,pass)),
    signIn : (cred) => dispatch(signIn(cred)),
    clearLoginError: () => dispatch(clearLoginError()),
    clearError: () => dispatch(clearError())
  };
}

const mapStateTopProps = (state) => {
  return state;
}

export default connect(mapStateTopProps,mapDispatchToProps)(Mfa);