import { db } from '../../config/fbConfig'
import { ref, child, set, onValue } from  'firebase/database'

const tagsRef = child(ref(db),'tags')
export const createTag = (tag) => {
  const tagRef = child(tagsRef,tag.tagId);
  return (dispatch, getState) => {
    set(tagRef,{
      ...tag
    }).then(()=>{
      dispatch({ type: 'CREATE_TAG', tag});
    }).catch((err) => {
      dispatch({ type: 'CREATE_TAG_ERROR', err });
    })
  }
}

export const fetchTags = () => {
  return (dispatch, getState) => {
    onValue(tagsRef , (snapshot)=>{
      const tags = snapshot.val();
      dispatch({ type: 'GET_TAGS', tags })
    })
  }
}