const initialState={
  urls:[]
}

export const lmsReducer = (state=initialState, action) => {
  switch(action.type){
    case 'VIDEO_URLS':
      return { ...state, urls: action.urls }
    case 'GET_LIBRARY':
      return { library: action.library }
    case 'NEW_FILES':
      return {...state, error:undefined, newFiles: action.filesList}
    case 'DELETED_FILES':
      return {...state, error:undefined, deletedFiles: action.filesList}
    case 'CURRENT_FILES':
      return {...state, error:undefined, currentFiles: action.filesList}
    case 'UPLOAD_COMPLETE':
      return {...state, error: undefined, success: true ,files: action.files}
    case 'UPLOAD_FILE_PROGRESS':
      return {...state, error: undefined, progress:action.progress, success: false }
    case 'CREATE_FOLDER':
      return {...state, error:undefined, folders: action.folders, success: false }
    case 'LIST_FILES_FOLDERS':
      return { error: undefined, folders:action.folders, files:action.files }
    case 'CREATE_FOLDER_ERROR':
      return {error: action.error};
    case 'DELETE_ITEM':
        return {...state, error: undefined, files: action.files, folders: action.folders, success: false}
    default:
      break;
  }
  return state;
}

export default lmsReducer;