import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createLead } from '../../store/actions/leadActions'
import { Container, Form, Row, Col, Button, Spinner, Figure } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'

const CreateLead = (props) => {
  const [disabled,setDisabled] = useState(false);
  const navigate = useNavigate();
  const [lead,setLead] = useState({
    cardUrl: '',
    photo: '',
    event: 'Umagine-Chennai-2023',
    orgName: '',
    leadName: '',
    context: '',
    email: '',
    emailSent: false
  })

  useEffect(()=>{
    if(props.toastMessage && props.toastMessage.message){
      setDisabled(false);
    }
  },[props.toastMessage])

  const handleChange = (e) => {
    setLead({ ...lead,
      [e.target.id]: e.target.value
    })
  }

  const reset = () => {
    setLead({
      cardUrl: '',
      photo: '',
      event: 'Umagine-Chennai-2023',
      orgName: '',
      leadName: '',
      context: '',
      email: '',
      emailSent: false
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    props.createLead(lead);
    reset();
  }

  const handlePhotoUpload = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setLead({
        ...lead,
        photo: reader.result,
        cardUrl: e.target.files[0]
      })
    }
  }
  
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
      <h3 className='grey-text text-darken-3'>Add Lead</h3>
      <Form.Group as={Row} className="mb-3" controlId="photo">
        <Form.Label className='fw-bold' column sm="2">
          Upload Photo
        </Form.Label>
        <Col sm="10">
          <Figure.Image src={lead.photo} alt='Photo' width={170} height={170}/>
          <Form.Control type='file' onChange={handlePhotoUpload} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="event"> 
        <Form.Label className='fw-bold' column sm="2">Event</Form.Label>
          <Col sm="10">
            <Form.Select onChange={handleChange} value={lead.event}>
              <option value="Umagine-Chennai-2023">Umagine-Chennai-2023</option>
              <option value="Convergence-India-2023">Convergence-India-2023</option>
              <option value="Generic">Generic</option>
            </Form.Select>
          </Col>
        </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="orgName">
        <Form.Label className='fw-bold' column sm="2">Org Name</Form.Label>
        <Col sm="10">
          <Form.Control onChange={handleChange} value={lead.orgName}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="leadName">
        <Form.Label className='fw-bold' column sm="2">Lead Name</Form.Label>
        <Col sm="10">
          <Form.Control onChange={handleChange} value={lead.leadName}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="context">
        <Form.Label className='fw-bold' column sm="2">Context</Form.Label>
        <Col sm="10">
          <Form.Control onChange={handleChange} value={lead.context}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="email">
        <Form.Label className='fw-bold' column sm="2">Email</Form.Label>
        <Col sm="10">
          <Form.Control type='email' onChange={handleChange} value={lead.email}/>
        </Col>
      </Form.Group>
      <Form.Group>
        <Button disabled={disabled} className='m-1' variant="success" type="submit">
          {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Add'}
        </Button>
        <Button className='m-1' variant="warning" onClick={()=> reset()}>
          Reset
        </Button>
        <Button className='m-1' variant="secondary" onClick={()=> navigate('/leads')}>
          Cancel
        </Button>
      </Form.Group>
    </Form>
  </Container>
)}

const mapDispatchToProps = (dispatch) => {
  return {
    createLead: (lead) => dispatch(createLead(lead))
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLead);
