import React from "react";
import { NavLink } from "react-router-dom";
import DeleteGroup from './DeleteGroup'


const GroupSummary = ({group, removeGroup}) => {
  return(
    <tr className="align-middle">
      <td>{group.id}</td>
      <td>{group.name}</td> 
      <td>
        <NavLink className="btn btn-success m-1 rounded-circle" to={`/group/${group.id}`}>
          <i className="bi bi-eye"></i>
        </NavLink>
        <NavLink className="btn btn-primary m-1 rounded-circle" to={`/group/edit/${group.id}`}>
          <i className="bi bi-pencil"></i>
        </NavLink>
        <DeleteGroup group={group} removeGroup={removeGroup}/>
      </td>
    </tr>
  );
}

export default GroupSummary;