const initialState={}

export const leaveReducer = (state=initialState, action) => {
  switch(action.type){
    case 'GET_LEAVES':
      return action.leaves;
    case 'CLEAR_LEAVES':
      return {};
    default:
      break;
  }
  return state;
}

export default leaveReducer;