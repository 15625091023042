import React from "react";
import DeleteInvitee from './DeleteInvitee'


const InviteeSummary = ({invitee, removeInvitee}) => {
  return(
    <tr className="align-middle">
      <td>{invitee.firstName} {invitee.lastName}</td>
      <td>{invitee.email}</td>
      <td>
        <DeleteInvitee invitee={invitee} removeInvitee={removeInvitee}/>
      </td>
    </tr>
  );
}

export default InviteeSummary;