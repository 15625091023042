import React from "react";
import RegistrantSummary from "./RegistrantSummary";
import { connect } from 'react-redux'
import { removeRegistrant } from "../../../store/actions/registrantActions";
import { Table, Container } from "react-bootstrap";

const RegistrantList = (props) => {
  const keys = props.registrants ? Object.keys(props.registrants) : []
  return(
    <Container className='p-2'>
      <Table striped bordered hover responsive id="registrantTable">
        <thead>
          <tr className="allign-middle">
            <th>Name</th>
            <th>Email</th>
            <th className="d-none d-lg-table-cell">Entity</th>
            <th className="d-none d-lg-table-cell">Designation</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? <tr><td colSpan={3}>'No Records Available'</td></tr>: ''}
          {keys && keys.map(id => {
            return(
              <RegistrantSummary key={id} registrant={props.registrants[id]} removeRegistrant={props.removeRegistrant}/>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}


const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeRegistrant: (registrant) => dispatch(removeRegistrant(registrant)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(RegistrantList);