import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { createLeave } from '../../store/actions/leaveActions';
import LeaveList from './LeaveList';

const MyCalendar = (props) => {
  const { uid } = props.auth.user;
  const leaves = (props.leaves && props.leaves[uid]) || []
  const applied = Object.keys(leaves).filter((id)=> leaves[id].approved===false)
  const approved = Object.keys(leaves).filter((id)=> leaves[id].approved===true)
  const [leave,setLeave] = useState({
    begin: new Date().toISOString().substring(0,10),
    end: new Date().toISOString().substring(0,10),
    duration: 0.5,
    month: '',
    approved: false,
    reason: ''})

  const handleChange = (e) => {
    setLeave({ ...leave,
      [e.target.id]: e.target.value
    })
  }

  const reset = () => {
    setLeave({
      begin: new Date().toISOString().substring(0,10),
      end: new Date().toISOString().substring(0,10),
      month: '', 
      duration: 0.5,
      approved: false,
      reason: ''})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    props.createLeave(leave,uid)
    reset();
  }

  return (
    <Row>
      <Col>
        <Container>
          <h3 className='grey-text text-darken-3'>Apply For Leave</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Col} className="mb-3" controlId="begin">
              <Form.Label className='fw-bold' column sm="2">Begin Date</Form.Label>
              <Col sm="10">
                <Form.Control required type="date" onChange={handleChange} value={leave.begin}/>
              </Col>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="end">
              <Form.Label className='fw-bold' column sm="2">End Date</Form.Label>
              <Col sm="10">
                <Form.Control required type="date" min={leave.begin} onChange={handleChange} value={leave.end}/>
              </Col>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="duration">
              <Form.Label className='fw-bold' column sm="2">Duration (Multiples of 0.5 days)</Form.Label>
              <Col sm="10">
                <Form.Control required type="number" step={0.5} onChange={handleChange} value={leave.duration}/>
              </Col>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="reason"> 
              <Form.Label className='fw-bold' column sm="2">Reason</Form.Label>
              <Col sm="10">
                <Form.Control required onChange={handleChange} value={leave.reason}/>
              </Col>
            </Form.Group>
            <Form.Group>
              <Button className='m-1' variant="success" type="submit">
                APPLY
              </Button>
              <Button className='m-1' variant="warning" onClick={()=> reset()}>
                RESET
              </Button>
            </Form.Group>
          </Form>
        </Container>
      </Col>
      <Col>
        <Row>
          <Container>
            <h3 className='grey-text text-darken-3'>Applied Leaves</h3>
            <LeaveList filterLeaves={applied} uid={uid}/>
          </Container>
        </Row>
        <Row>
          <Container>
            <h3 className='grey-text text-darken-3'>Approved Leaves</h3>
            <LeaveList filterLeaves={approved} uid={uid}/>
          </Container>
        </Row>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    createLeave : (leave,uid) => dispatch(createLeave(leave,uid))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(MyCalendar);
