import { ref, uploadBytes, listAll, uploadBytesResumable, deleteObject, getDownloadURL } from "firebase/storage"
import { storage, db } from "../../config/fbConfig"
import { ref as dbref, child, onValue, set } from "firebase/database"

const libraryRef = child(dbref(db),'lmsLibrary');
const groupsRef = child(dbref(db),'groups');
export const createNewFolder = (folderName) => {
  const folderRef = ref(storage,folderName)
  return (dispatch,getState) => {
    uploadBytes(ref(folderRef,'.keep'), new Blob([],{type: "application/octet-stream" })).then(()=>{
      dispatch({type:'CREATE_FOLDER',folders: [...getState().lms.folders,folderName]})
    }).catch((err)=>{
      dispatch({type:'CREATE_FOLDER_ERROR',error: err})
    })
  }
}

export const listFilesAndFolders = (location) => {
  return (dispatch,getState) => {
    listAll(ref(storage,location)).then(result => {
      const folderList = result.prefixes.map(prefix => prefix.fullPath);
      const fileList = result.items.filter(item => !(item.fullPath.endsWith('.keep'))).map(item => item.fullPath);
      dispatch({type: 'LIST_FILES_FOLDERS',folders: folderList,files: fileList})
    })
  }
}

export const uploadVideo = (location,file) => {
  return (dispatch,getState) => {
    const uploadTask = uploadBytesResumable(ref(storage,location+'/'+file.name),file)
    uploadTask.on('state_changed', (ss)=> {
      var progress = ss.bytesTransferred / ss.totalBytes
      dispatch({type: 'UPLOAD_FILE_PROGRESS', progress})
    },
      null,
      ()=>{
      dispatch({type: 'UPLOAD_COMPLETE', files: [...getState().lms.files,location+'/'+file.name]})
    })
  }
}

export const deleteItems = (items) => {
  return (dispatch,getState) => {
    items.forEach(item => {
      deleteObject(ref(storage,item)).then(()=>{
        const files = getState().lms.files;
        const folders = getState().lms.folders;
        if(item.endsWith('.mp4')){
          files.splice(files.indexOf(item),1);
        } else {
          folders.splice(folders.indexOf(item),1);
        }
        dispatch({type: 'DELETE_ITEM',files , folders })
      })
    })
  }
}

function listAllFilesOnStorage(path) {
  return new Promise(function(resolve, reject) {
    var folderRef = ref(storage, path);
    var files = [];
    listAll(folderRef).then(function(result) {
      result.items.forEach(function(itemRef) {
        if(itemRef.fullPath.endsWith('.mp4')) {
          files.push(itemRef.fullPath);
        }
      });

      var promises = result.prefixes.map(function(prefixRef) {
        return listAllFilesOnStorage(prefixRef.fullPath);
      });

      Promise.all(promises).then(function(results) {
        results.forEach(function(subFiles) {
          files = files.concat(subFiles);
        });
        resolve(files);
      }).catch(function(error) {
        reject(error);
      });
    }).catch(function(error) {
      reject(error);
    });
  })
}

export const listOfCurrentFiles = () => {
  const filesList = []
  return (dispatch,getState) => {
    listAllFilesOnStorage('/').then((files)=>{
      // onValue(libraryRef,(ss)=>{
      //   console.log("SS",ss)
      //   filesList.push(...files.filter(file=>Object.values(ss.val()).indexOf(file)>-1))
      // })
      dispatch({type: 'CURRENT_FILES', filesList})
    })
  }
}

export const listOfDeletedFiles = () => {
  const filesList = []
  return (dispatch,getState) => {
    listAllFilesOnStorage('/').then((files)=>{
      if(files.length()>0){
        onValue(libraryRef,(ss)=>{
          filesList.push(...Object.values(ss.val()).filter(file=>files.indexOf(file)===-1))
        })
      }
      dispatch({type: 'DELETED_FILES', filesList})
    })
  }
}

export const listOfNewFiles = () => {
  const filesList = []
  return (dispatch,getState) => {
    listAllFilesOnStorage('/').then((files)=>{
      onValue(libraryRef,(ss)=>{
        filesList.push(...files.filter(file=>Object.values(ss.val()).indexOf(file)===-1))
      })
      dispatch({type: 'NEW_FILES', filesList})
    })
  }
}

export const addToLibrary = (alias,location) => {
  return (dispatch,getState) => {
    set(child(libraryRef,alias),location).then(()=>{
      dispatch({type: 'ADD_TO_LIBRARY'})
    })
  }
}

export const getLibrary = () => {
  return (dispatch,getState) => {
    onValue(libraryRef, ss => {
      dispatch({type: 'GET_LIBRARY', library: ss.val()})
    })
  }
}

export const getVideoURLs = (groups) => {
  return (dispatch,getState) => {
    groups.forEach(group => {
      onValue(child(groupsRef,group),ss => {
        ss.val().videos.forEach(video => {
          getDownloadURL(ref(storage,video.label)).then(url => {
            dispatch({type: 'VIDEO_URLS', urls: [...getState().lms.urls,url]})
          })
        })
      })
    })
  }
}
