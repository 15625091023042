import { useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row, Modal, ProgressBar } from "react-bootstrap";
import Question from "./Question";
import { PDFViewer } from "@react-pdf/renderer";
import Certificate from "./Certificate"

const QuizPage = (props) => {
    const { uid } = props.auth.user
    const [score,setScore] = useState(parseFloat(0));
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
      props.setStatus(uid,{...props.security.status, currentVideo:11, score:{
        ...props.security.status.score , 11: score
    }})
      setShow(true)
    };
    const [qno,setQno] = useState(0);
    const quizList = props.security['quizList'][props.count];
    const increaseScore = () => {
        setScore(score+parseFloat(100/Object.keys(quizList).length))
    }
    const nextQuestion = () => {
        setQno(qno+1)
    }
    const retake = () => {
        setQno(0)
        setScore(0)
    }
    const calculateProgress = () => {
        return (qno)*100 / Object.keys(quizList).length;
    }
  return(
    <Container>
      <h1>{props.title} {qno < Object.keys(quizList).length ? qno+1 : qno} / {Object.keys(quizList).length}</h1>
      <ProgressBar now={calculateProgress()} />
      <Col>
        <Row>
            {
                qno<Object.keys(quizList).length ? <Question key={qno} question={quizList[qno]} score={score} increaseScore={increaseScore} nextQuestion={nextQuestion}/> : score<80 ? <><h3>Score: {score}% - Failed</h3> <Button onClick={retake}>Retake</Button> </>: ''
            }
        </Row>
        <Row className="justify-content-center">
        {
          props.count<11 && score>80 ?
            <>
            <h3>Score: {score}% - Passed</h3>
            <Button className='m-1' variant="secondary" onClick={()=> props.increaseCountWithScore(score)}>
                Finish And Next
            </Button></> : props.count===11 && score>80 ?
            <Button className='m-1' variant="success" onClick={()=> handleShow(score)}>
                Complete
            </Button> : ''
        }
        </Row>
      </Col>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PDFViewer width="1000" height="600">
            <Certificate
              studentName="Natarajan" //{studentName}
              courseName="Cyber Security Awareness"
              completionDate="2024-05-13" //{completionDate}
            />
          </PDFViewer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>);
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(QuizPage);