import { Container } from "react-bootstrap"
import VideoPage from "./VideoPage";
import QuizPage from "./QuizPage";
import { useState } from "react";
import { connect } from "react-redux";
import { setStatus } from "../../store/actions/securityActions";

const CourseContent = (props) => {
    const { uid } = props.auth.user;
    const [currentVideo,setCurrentVideo] = useState(props.security.status ? props.security.status.currentVideo : 0)
    const increaseVideoCount = () => {
        setCurrentVideo(currentVideo+1) 
        props.setStatus(uid,{...props.security.status, currentVideo:currentVideo+1 })
    }
    const increaseCountWithScores = (score) => {
        setCurrentVideo(currentVideo+1)
        props.setStatus(uid,{...props.security.status, currentVideo:currentVideo+1, score:{
            ...props.security.status.score , [currentVideo]: score
        }})
    }
    return(
        <Container>
            {props.security.urls[currentVideo][2] === 'video' ? <VideoPage increaseCount={increaseVideoCount} count={currentVideo} title={props.security.urls[currentVideo][0]} url={props.security.urls[currentVideo][1]} /> : <QuizPage increaseCountWithScore={increaseCountWithScores} count={currentVideo} title={props.security.urls[currentVideo][0]}/>}
        </Container>
    )
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStatus: (uid,status) => dispatch(setStatus(uid,status))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CourseContent);