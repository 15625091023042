import { db } from '../../config/fbConfig'
import { ref, child, set, onValue, push, remove } from  'firebase/database'

const leavesRef = child(ref(db),'leaves')
export const createLeave = (leave,uid) => {
  const employeeRef = child(leavesRef,uid);
  return (dispatch, getState) => {
    push(employeeRef,{
      ...leave
    }).then(()=>{
      dispatch({ type: 'CREATE_LEAVE', leave});
    }).catch((err) => {
      dispatch({ type: 'CREATE_LEAVE_ERROR', err });
    })
  }
}

export const fetchLeaves = () => {
  return (dispatch, getState) => {
    onValue(leavesRef , (snapshot)=>{
      const leaves = snapshot.val();
      dispatch({ type: 'GET_LEAVES', leaves })
    })
  }
}

export const fetchLeavesByUid = (uid) => {
  return (dispatch, getState) => {
    onValue(child(leavesRef,uid) , (snapshot)=>{
      const leaves = snapshot.val();
      dispatch({ type: 'GET_LEAVES', leaves:{[uid]:leaves} })
    })
  }
}

export const removeLeave = (uid,leaveId) => {
  return (dispatch, getState) => {
    const leaveRef = child(child(leavesRef,uid),leaveId);
    remove(leaveRef).then(()=> {
      dispatch({type: 'REMOVE_LEAVE', leaveId})
    }).catch((err) => {
      dispatch({ type: 'REMOVE_LEAVE_ERROR', err })
    })
  }
}

export const approveLeave = (uid,leaveId,month) => {
  return (dispatch, getState) => {
    const leaveRef = child(child(leavesRef,uid),leaveId);
    set(child(leaveRef,'approved'),true).then(()=> {
      set(child(leaveRef,'month'),month);
      dispatch({type: 'APPROVE_LEAVE', leaveId})
    }).catch((err) => {
      dispatch({ type: 'APPROVE_LEAVE_ERROR', err })
    })
  }
}