import React, { useState } from "react";
import { Container, Table, Row, Col, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom'
import { createChronology } from '../../../store/actions/chronActions'

const ChronologyDetails = (props) => {
  const { id }  = useParams();
  const navigate = useNavigate();
  const [chronology,setChronology] = useState(props.chronologies && props.chronologies[id] ? props.chronologies[id] : [])
  const [change,setChange] = useState(false)
  const [newChron,setNewChron] = useState(false)
  const handleChange = (e) => {
    const [ firstChron , ...rest ] = chronology
    const updatedFirstChron = {
      ...firstChron,
      chronId: id,
      [e.target.id] : e.target.value
    }
    setChronology([updatedFirstChron,...rest])
    setChange(true)
  }
  const handleSubmit = () => {
    props.createChronology(chronology,id)
    navigate('/inventories')
  }
  const addChron = () => {
    setChronology([{empId: Object.keys(props.employees)[0],start:'',end:''},...chronology])
    setNewChron(true)
  }

  return(
    <Container>
      <Row>
        <Col>
          <h2> Chronology </h2>
        </Col>
        <Col className="m-1 d-flex flex-row-reverse">
          {newChron ? null : 
            <Button variant="danger" onClick={addChron}>
              New Assignee
            </Button>
          }
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>Employee Name</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
          <tbody>
            {chronology.length > 0 && chronology.map((chron,i)=>{
              if(i===0 && newChron){
                return(<tr key={i}>
                  <td>
                    <Form.Select id="empId" onChange={handleChange} value={chron.empId}>
                      {Object.keys(props.employees).map((empId) => {
                        return(
                          <option key={empId} value={empId}>{props.employees[empId].firstName} {props.employees[empId].lastName}</option>
                        )
                      })}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Control required id="start" type="date" onChange={handleChange} value={chron.start}/>
                  </td>
                  <td>
                    <Form.Control id="end" type="date" onChange={handleChange} value={chron.end} min={chron.start}/>
                  </td>
                </tr>)
              } else {
              return(
                <tr key={i}>
                  <td>{props.employees[chron.empId].firstName} {props.employees[chron.empId].lastName}</td>
                  <td>{chron.start}</td>
                  <td>
                  {(i===0)?
                    <Form.Control id="end" type="date" onChange={handleChange} value={chron.end} min={chron.start}/> :
                    chron.end}
                  </td>
                </tr>
              )}}
            )}
          </tbody>
      </Table>
      <Row>
        {change &&
          (<Button className='m-1' variant="success" onClick={handleSubmit}>
            SAVE
          </Button>)
        }
        <Button className='m-1' variant="secondary" onClick={()=> navigate('/inventories')}>
          CANCEL
        </Button>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    createChronology: (chronology,invId) => dispatch(createChronology(chronology,invId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChronologyDetails);