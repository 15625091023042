import { db, auth, functions, storage } from '../../config/fbConfig'
import { ref, child, set, onValue, remove } from  'firebase/database'
import { httpsCallable } from 'firebase/functions'
import { uploadBytes, ref as stRef, getDownloadURL } from 'firebase/storage'

const registrantsRef = child(ref(db),'registrants')
const storageRef = stRef(storage,'Profiles')
export const createRegistrant = (registrant) => {
  delete registrant.photo
  const reader = new FileReader();
  reader.readAsArrayBuffer(registrant.imageUrl)
  const extension = String(registrant.imageUrl.type).split('/')[1];
  const id = auth.currentUser.uid;
  const registrantRef = child(registrantsRef,id);
  return (dispatch, getState) => {
    reader.onload = (e) => {
      const array = new Uint8Array(e.target.result)
      uploadBytes(stRef(storageRef,`${id}.${extension}`),array).then((ss)=>{
        getDownloadURL(ss.ref).then((url)=>{
        registrant.imageUrl = url;
          set(registrantRef,{
            ...registrant,
            id,
            email: auth.currentUser.email
          }).then(()=>{
            const sendRegisterEmployeeEmail = httpsCallable(functions, 'sendRegisterEmployeeEmailFunction');
            const sendRegisterAdminEmail = httpsCallable(functions, 'sendRegisterAdminEmailFunction');
            sendRegisterEmployeeEmail({email: auth.currentUser.email, name: registrant.firstName+' '+registrant.lastName});
            sendRegisterAdminEmail({email: auth.currentUser.email}).then(()=>{
              dispatch({ type: 'CREATE_REGISTRANT', registrant});
            })
          }).catch((err) => {
            dispatch({ type: 'CREATE_REGISTRANT_ERROR', err });
          })
          const deactivateAccount = httpsCallable(functions,'deactivateAccountFunction')
          remove(child(child(ref(db),'invited'),id)).then(()=> {
            deactivateAccount()
            .then(()=>{
              auth.signOut();
              dispatch({type: 'REMOVE_INVITEE'})
            })
          }).catch((err) => {
            dispatch({ type: 'REMOVE_INVITEE_ERROR', err })
          })
        })
      })
    }
  }
}

export const fetchRegistrants = () => {
  return (dispatch, getState) => {
    onValue(registrantsRef , (snapshot)=>{
      const registrants = snapshot.val();
      dispatch({ type: 'GET_REGISTRANTS', registrants })
    })
  }
}

export const removeRegistrant = (registrant) => {
  const exRegistrantsRef = child(child(ref(db),'exRegistrants'),registrant.id)
  return (dispatch, getState) => {
    const removeRef = child(registrantsRef,registrant.id);
    const deleteAccount = httpsCallable(functions,'deleteAccountFunction')
    remove(removeRef).then(()=> {
      deleteAccount({uid:registrant.id}).then(()=>{
        dispatch({type: 'REMOVE_REGISTRANT', registrant})
      })
    }).catch((err) => {
      dispatch({ type: 'REMOVE_REGISTRANT_ERROR', err })
    })
    set(exRegistrantsRef,registrant);
  }
}