import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { addToLibrary } from '../../../store/actions/lmsActions';

const AddToLibrary = (props) => {
  const [show, setShow] = useState(false);
  const [alias,setAlias] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return(
    <>
      <span className="btn btn-success m-1 rounded-circle modal-trigger" onClick={handleShow}>
        <i className="bi bi-plus-circle"></i>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Video To Library ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{props.location}</p>
          <Form.Group as={Row} className="mb-3" controlId="alias"> 
            <Form.Label className='fw-bold' column sm="2">Alias: </Form.Label>
            <Col sm="5">
              <Form.Control required onChange={(e)=>setAlias(e.target.value)} value={alias}/>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            CLOSE
          </Button>
          <Button variant="primary" onClick={()=> {props.addToLibrary(alias,props.location);setAlias('');handleClose()}}>
            ADD
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
} 

const mapDispatchToProps = (dispatch) => {
  return {
    addToLibrary : (alias,location) => dispatch(addToLibrary(alias,location))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToLibrary);