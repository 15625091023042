const initialState={}

export const inventoryReducer = (state=initialState, action) => {
  switch(action.type){
    case 'GET_INVENTORIES':
      return action.inventories;
    case 'REMOVE_INVENTORY':
      return state;
    case 'CLEAR_INVENTORIES':
      return {};
    default:
      break;
  }
  return state;
}

export default inventoryReducer;