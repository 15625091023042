const initialState={
  category: undefined,
  authError: undefined,
  resetError: undefined,
  user: undefined
}

export const authReducer = (state=initialState, action) => {
  switch(action.type){
    case 'LOGIN_ERROR':
      return {
        ...state,
        user: undefined,
        resetError: undefined,
        category: 'LOGIN',
        authError: action.err,
      }
    case 'LOGIN_SUCCESS':
      return{
        ...state,
        category: action.category,
        groups: action.groups,
        user: action.user,
        authError: undefined,
        resetError: undefined
      }
    case 'SIGNOUT_SUCCESS':
      return {
        ...state,
        category: 'LOGIN',
        user: undefined,
        authError: undefined,
        resetError: undefined
      };
    case 'RESET_SUCCESS':
      return {
        ...state,
        category: 'LOGIN',
        user: undefined,
        authError: '',
        resetError: undefined
      }
    case 'RESET_ERROR':
      return {
        ...state,
        category: 'LOGIN',
        user: undefined,
        authError: undefined,
        resetError: action.err
      }
    default:
      return state;
  }
}

export default authReducer;