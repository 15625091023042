import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { signIn, clearError } from '../../store/actions/authActions'
import { Container , Form, Button, Alert, Spinner, Nav } from 'react-bootstrap';

const SignIn =(props) => {
  const [disabled,setDisabled] = useState(false);
  const [error,setError]  = useState(props.authError);
  const [credentials,setCredentials] = useState({
    email:'',
    password: '',
    totp: ''
  })

  useEffect(()=>{
    setError(props.authError);
    if(props.authError){
      setDisabled(false);
    }
  },[props.authError])

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.id]: e.target.value
    })
  }

  const clearError = (e) => {
    setError('')
    props.clearError();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    clearError();
    setDisabled(true);
    props.signIn(credentials)
    setCredentials({
      ...credentials,
      password: '',
      totp: ''
    })
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control required type="email" placeholder="Enter email" value={credentials.email} onChange={handleChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control required type="password" placeholder="Password" value={credentials.password} onChange={handleChange}/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="totp">
          <Form.Label>TOTP</Form.Label>
          <Form.Control required maxLength={6} placeholder="TOTP" value={credentials.totp} onChange={handleChange}/>
        </Form.Group>

        <Nav.Link style={{color: 'blue'}} href='/reset'>Forgot Password?</Nav.Link>

        <Nav.Link style={{color: 'blue'}} href='/mfa'>Enroll MFA.</Nav.Link>

        <Form.Group className='mb-3' controlId="errorMessage">
          <Form.Text id='error'>
            {error ?
              <Alert key='error' variant='danger'>
                <div className='d-flex justify-content-between'>
                  <span>
                    {error}
                  </span>
                  <span onClick={clearError}>
                    X
                  </span>
                </div> 
              </Alert> : null}
          </Form.Text>
        </Form.Group>

        <Button disabled={disabled} id="sign-in" variant="primary" type="submit">
          {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Sign In'}
        </Button>
      </Form>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    clearError: () => dispatch(clearError())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SignIn);
