const initialState = {}

export const mfaReducer = (state=initialState,action) => {
  switch(action.type){
    case 'CODE_SENT':
      return {verificationId: action.verificationId};
    case 'OTP_SUCCESS':
      return {otpSet: true};
    case 'DISPLAY_QR':
      return {qrImgData : action.dataUrl}
    case 'ALREADY_CONFIGURED':
      return {error: action.error}
    default:
      break;
  }
  return state;
}

export default mfaReducer;