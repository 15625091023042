import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { createEmployee } from '../../../store/actions/employeeActions'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Form, Row, Col, Button, Figure } from 'react-bootstrap';

const ApproveRegistrant = (props) => {
  const { id }  = useParams();
  const [employee,setEmployee] = useState({...useSelector((state) => state.registrants[id]),category: "Full Time"});
  const navigate = useNavigate();
 
  const handleSubmit = (e) => {
    e.preventDefault();
    props.createEmployee(employee)
    navigate('/employees')
  }

  const handleChange = (e) => {
    setEmployee({...employee,
      [e.target.id]: e.target.value
    })
  }
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
      <h3 className='grey-text text-darken-3'>Approve Registrant</h3>
      <Figure.Image src={employee.imageUrl} alt='Photo' width={170} height={170}/>
      <Form.Group as={Row} className="mb-3" controlId="firstName">
        <Form.Label className='fw-bold' column sm="2">First Name</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={employee.firstName} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="lastName">
        <Form.Label className='fw-bold' column sm="2">Last Name</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={employee.lastName}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="dob">
        <Form.Label className='fw-bold' column sm="2">Date Of Birth</Form.Label>
        <Col sm="10">
          <Form.Control required type="date" onChange={handleChange} value={employee.dob}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="address">
        <Form.Label className='fw-bold' column sm="2">Address</Form.Label>
        <Col sm="10">
          <Form.Control required as="textarea" row={3} onChange={handleChange} value={employee.address}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="entity"> 
        <Form.Label className='fw-bold' column sm="2">Entity</Form.Label>
        <Col sm="10">
          <Form.Select onChange={handleChange} value={employee.entity}>
            <option value="Exgen Systems LLC">Exgen Systems LLC</option>
            <option value="Adaptiv Work">Adaptiv Work</option>
            <option value="Secure Traces">Secure Traces</option>
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="doj">
        <Form.Label className='fw-bold' column sm="2">Date Of Joining</Form.Label>
        <Col sm="10">
          <Form.Control required type="date" onChange={handleChange} value={employee.doj}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="designation">
        <Form.Label className='fw-bold' column sm="2">Designation</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={employee.designation}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="email">
        <Form.Label className='fw-bold' column sm="2">Email</Form.Label>
        <Col sm="10">
          <Form.Control required type='email' readOnly plaintext value={employee.email}/>
        </Col>
      </Form.Group> 
      <Form.Group as={Row} className="mb-3" controlId="personalEmail">
        <Form.Label className='fw-bold' column sm="2">Personal Email</Form.Label>
        <Col sm="10">
          <Form.Control required type='email' onChange={handleChange} value={employee.personalEmail}/>
        </Col>
      </Form.Group> 
      <Form.Group as={Row} className="mb-3" controlId="mobile">
        <Form.Label className='fw-bold' column sm="2">Mobile</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={employee.mobile}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="empId">
        <Form.Label className='fw-bold' column sm="2">Employee ID</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={employee.empId}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="category">
        <Form.Label className='fw-bold' column sm="2">Category</Form.Label>
        <Col sm="10">
          <Form.Select required onChange={handleChange} value={employee.category}>
            <option value="Full Time">Full Time</option>
            <option value="Contractor">Contractor</option>
            <option value="Trainee">Trainee</option>
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group>
        <Button className='m-1' variant="warning" type="submit">
          APPROVE
        </Button>
        <Button className='m-1' variant="secondary" onClick={()=> navigate('/employees')}>
          CANCEL
        </Button>
      </Form.Group>
      </Form>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    createEmployee: (employee) => dispatch(createEmployee(employee))
  }
}

export default connect(null, mapDispatchToProps)(ApproveRegistrant);
