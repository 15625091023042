import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteInventory = ({inventory, removeInventory}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return(
    <>
      <span className="btn btn-danger m-1 rounded-circle modal-trigger" onClick={handleShow}>
        <i className="bi bi-trash"></i>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Inventory?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>ID: {inventory.invId}</p>
          <p>Name: {inventory.name}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={()=> {removeInventory(inventory)}}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteInventory;