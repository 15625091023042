import React, {useState} from 'react'
import { connect } from 'react-redux'
import { updateLead } from '../../store/actions/leadActions'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Form, Row, Col, Button, Figure } from 'react-bootstrap';

const EditLead = (props) => {
  const  { id, event } = useParams()
  const [lead,setLead] = useState(props.leads[id]);
  const navigate = useNavigate();
 
  const handleSubmit = (e) => {
    e.preventDefault();
    props.updateLead(event,id,lead);
    navigate('/leads')
  }

  const handleChange = (e) => {
    setLead({...lead,
      [e.target.id]: e.target.value
    })
  }

  const handlePhotoUpload = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setLead({
        ...lead,
        photo: reader.result,
        cardUrl: e.target.files[0]
      })
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
      <h3 className='grey-text text-darken-3'>Edit Lead</h3>
      <Form.Group as={Row} className="mb-3" controlId="photo">
        <Form.Label className='fw-bold' column sm="2">
          Upload Photo
        </Form.Label>
        <Col sm="10">
          <Figure.Image src={lead.photo || lead.cardUrl} alt='Photo' width={400} height={400}/>
          <Form.Control type='file' onChange={handlePhotoUpload} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="event"> 
            <Form.Label className='fw-bold' column sm="2">Event</Form.Label>
            <Col sm="10">
              <Form.Select onChange={handleChange} value={lead.event}>
                <option value="Umagine-Chennai-2023">Umagine-Chennai-2023</option>
                <option value="Convergence-India-2023">Convergence-India-2023</option>
                <option value="Generic">Generic</option>
              </Form.Select>
            </Col>
          </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="orgName">
        <Form.Label className='fw-bold' column sm="2">Org Name</Form.Label>
        <Col sm="10">
          <Form.Control onChange={handleChange} value={lead.orgName}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="leadName">
        <Form.Label className='fw-bold' column sm="2">Lead Name</Form.Label>
        <Col sm="10">
          <Form.Control onChange={handleChange} value={lead.leadName}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="context">
        <Form.Label className='fw-bold' column sm="2">Context</Form.Label>
        <Col sm="10">
          <Form.Control onChange={handleChange} value={lead.context}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="email">
        <Form.Label className='fw-bold' column sm="2">Email</Form.Label>
        <Col sm="10">
          <Form.Control type='email' onChange={handleChange} value={lead.email}/>
        </Col>
      </Form.Group>
      <Form.Group>
        <Button className='m-1' variant="success" type="submit">
          Update
        </Button>
        <Button className='m-1' variant="secondary" onClick={()=> navigate('/leads')}>
          Cancel
        </Button>
      </Form.Group>
    </Form>
  </Container>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateLead: (event,id,lead) => dispatch(updateLead(event,id,lead))
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLead);
