import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import { Container, Form, Row, Col, Button, Figure } from 'react-bootstrap';

const LeadDetails = (props) => {
  const { id, event } = useParams();
  const lead = props.leads[id]
  const navigate = useNavigate();
  if(lead){
    return (
      <Row>
        <Col>
          <Container>
            <h3 className='grey-text text-darken-3'>Lead Details</h3>
            <Form>
              <Figure.Image src={lead.cardUrl} alt='Photo' width={400} height={400}/>
              <Form.Group as={Row} className="mb-3" controlId="orgName">
                <Form.Label className='fw-bold' column sm="2">
                  Org:
                </Form.Label>
                <Col sm="10">
                  <Form.Control plaintext readOnly defaultValue={lead.orgName} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="leadName">
                <Form.Label className='fw-bold' column sm="2">
                  Lead:
                </Form.Label>
                <Col sm="10">
                  <Form.Control plaintext readOnly defaultValue={lead.leadName} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="context">
                <Form.Label className='fw-bold' column sm="2">
                  Context:
                </Form.Label>
                <Col sm="10">
                  <Form.Control plaintext readOnly defaultValue={lead.context} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="email">
                <Form.Label className='fw-bold' column sm="2">
                  Email:
                </Form.Label>
                <Col sm="10">
                  <Form.Control plaintext readOnly defaultValue={lead.email} />
                </Col>
              </Form.Group>
              <Button variant="secondary" onClick={()=> navigate('/leads')}>
                BACK
              </Button>
            </Form>
          </Container>
        </Col>
      </Row>
    )
  } else {
    return(
      <div className='container'>
        Loading....
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(LeadDetails);
