import { db } from '../../config/fbConfig'
import { ref, child, set, onValue, remove } from  'firebase/database'

const inventoriesRef = child(ref(db),'inventories')
export const createInventory = (inventory) => {
  const inventoryRef = child(inventoriesRef,inventory.invId);
  return (dispatch, getState) => {
    set(inventoryRef,{
      ...inventory
    }).then(()=>{
      dispatch({ type: 'CREATE_INVENTORY', inventory});
    }).catch((err) => {
      dispatch({ type: 'CREATE_INVENTORY_ERROR', err });
    })
  }
}

export const fetchInventories = () => {
  return (dispatch, getState) => {
    onValue(inventoriesRef , (snapshot)=>{
      const inventories = snapshot.val();
      dispatch({ type: 'GET_INVENTORIES', inventories })
    })
  }
}

export const removeInventory = (inventory) => {
  const exinventoriesRef = child(child(ref(db),'exInventories'),inventory.invId)
  return (dispatch, getState) => {
    const removeRef = child(inventoriesRef,inventory.invId);
    remove(removeRef).then(()=> {
      dispatch({type: 'REMOVE_INVENTORY', inventory})
    }).catch((err) => {
      dispatch({ type: 'REMOVE_INVENTORY_ERROR', err })
    })
    set(exinventoriesRef,inventory);
  }
}