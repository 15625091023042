import { db, functions } from '../../config/fbConfig'
import { ref, child, set, onValue, remove } from  'firebase/database'
import { httpsCallable } from 'firebase/functions'

const inviteesRef = child(ref(db),'invited')
export const createInvitee = (invitee) => {
  return (dispatch, getState) => {
    const sendInviteEmail = httpsCallable(functions, 'sendInviteEmailFunction');
    sendInviteEmail({
      email: invitee.email,
      name: invitee.firstName+' '+invitee.lastName
    }).then((result) => {
      const inviteeRef = child(inviteesRef,result.data.uid);
      set(inviteeRef,{
        ...invitee,
        uid: result.data.uid
      }).then(()=>{
        dispatch({ type: 'CREATE_INVITEE' });
      }).catch((err) => {
        dispatch({ type: 'INVITEE_ERROR', err });
      })
    }).catch((err)=>{
    })
  }
}

export const fetchInvitees = () => {
  return (dispatch, getState) => {
    onValue(inviteesRef , (snapshot)=>{
      const invitees = snapshot.val();
      dispatch({ type: 'GET_INVITEES', invitees })
    })
  }
}

export const removeInvitee = (invitee) => {
  return (dispatch, getState) => {
    const removeRef = child(inviteesRef,invitee.uid);
    const deleteAccount = httpsCallable(functions,'deleteAccountFunction')
    remove(removeRef).then(()=> {
      deleteAccount({uid:invitee.uid}).then(()=>{
        dispatch({type: 'REMOVE_INVITEE', invitee})
      })      
    }).catch((err) => {
      dispatch({ type: 'REMOVE_INVITEE_ERROR', err })
    })
  }
}