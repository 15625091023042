import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row } from "react-bootstrap";
import { GiCyberEye } from "react-icons/gi";
import CardIcon from './CardIcon';
import { getStatus } from "../../store/actions/securityActions";

const CoursePage = (props) => {
  const { uid } = props.auth.user;
  useEffect(()=>{
    props.getStatus(uid);
  },[uid])
  return (
    <Container className="p-3">
      <h1> Courses </h1>
      <Row>
        <CardIcon to='/lms/security' title="CyberSecurity Awareness" icon="people">
          <GiCyberEye />
        </CardIcon>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    getStatus: (uid) => dispatch(getStatus(uid))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CoursePage);