import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createGroup } from '../../store/actions/groupActions'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import Select from 'react-select';
import { getLibrary } from '../../store/actions/lmsActions';

const CreateGroup = (props) => {

  useEffect(()=>{
    props.getLibrary();
  },[])

  const navigate = useNavigate();
  const [group,setGroup] = useState({
    id: '',
    employees: [],
    name: '',
    videos: []
  })

  const employeeOptions = Object.keys(props.employees).map((empId)=>{
    return {value:empId, label: props.employees[empId].firstName +' '+ props.employees[empId].lastName}
  })

  const videoOptions = props.lms && props.lms.library && Object.keys(props.lms.library).map((key)=>{
    return {value:key, label: props.lms.library[key]}
  })

  const handleChange = (e) => {
    setGroup({ ...group,
      [e.target.id]: e.target.value
    })
  }

  const employeeUpdated = (val) => {
    setGroup({ ...group,
      employees : val
    })
  }

  const videoUpdated = (val) => {
    setGroup({ ...group,
      videos : val
    })
  }

  const reset = () => {
    setGroup({
      id: '',
      employees: [],
      name: '',
      videos: []
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    props.createGroup(group)
    reset();
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <h3 className='grey-text text-darken-3'>Create Group</h3>
        <Form.Group as={Row} className="mb-3" controlId="id">
          <Form.Label className='fw-bold' column sm="2">Group ID</Form.Label>
          <Col sm="10">
            <Form.Control required onChange={handleChange} value={group.id}/>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="name">
          <Form.Label className='fw-bold' column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control required onChange={handleChange} value={group.name} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="employees">
          <Form.Label className='fw-bold' column sm="2">
            Employees
          </Form.Label>
          <Col sm="10">
            <Select isMulti value={group.employees} onChange={employeeUpdated} options={employeeOptions} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="videos">
          <Form.Label className='fw-bold' column sm="2">
            Videos
          </Form.Label>
          <Col sm="10">
            <Select isMulti value={group.videos} onChange={videoUpdated} options={videoOptions} />
          </Col>
        </Form.Group>
        <Form.Group>
          <Button className='m-1' variant="success" type="submit">
            CREATE
          </Button>
          <Button className='m-1' variant="warning" onClick={()=> reset()}>
            RESET
          </Button>
          <Button className='m-1' variant="secondary" onClick={()=> navigate('/groups')}>
            CANCEL
          </Button>
        </Form.Group>
      </Form>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    createGroup: (group) => dispatch(createGroup(group)),
    getLibrary: () => dispatch(getLibrary())
  }
}

const mapStateToProps = (state) => {
  return state
} 

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
