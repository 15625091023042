import React, {useState, useEffect} from "react";
import { Breadcrumb, Container, Row, Col, Button, Modal, Form, ProgressBar } from "react-bootstrap";
import CardIcon from "./CardIcon";
import { connect } from 'react-redux'
import { FcFilmReel, FcFolder } from "react-icons/fc";
import { NavLink } from "react-router-dom";
import { createNewFolder, deleteItems, listFilesAndFolders, uploadVideo } from "../../store/actions/lmsActions";

const FileManager = (props) => {
  const [showUpload, setShowUpload] = useState(false);
  const [folderName,setFolderName] = useState('');
  const handleCloseUpload = () => setShowUpload(false);
  const handleShowUpload = () => setShowUpload(true);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const handleCloseCreateFolder = () => setShowCreateFolder(false);
  const handleShowCreateFolder = () => setShowCreateFolder(true);
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const [location,setLocation] = useState('');
  const [files,setFiles] = useState([]);
  const [folders,setFolders] = useState([]);
  const [uploadFile,setUploadFile] = useState();
  const [progress,setProgress] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options,setOptions] = useState([])

  const handleFileAttachment = (e) => {
    setUploadFile(e.target.files[0])
  }

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  useEffect(()=>{
    if(props.lms.files && props.lms.folders)
    {
      setFiles(props.lms.files)
      setFolders(props.lms.folders)
      setOptions([...props.lms.files,...props.lms.folders])
    }
  },[props.lms.files,props.lms.folders])

  useEffect(()=>{
    props.listFilesAndFolders(location);
  },[location])

  useEffect(()=>{
    setProgress(props.lms.progress);
  },[props.lms.progress])

  useEffect(()=>{
    if(props.lms.success) {
      handleCloseUpload()
    }
  },[props.lms.success])

  return(
    <Container>
      <Row>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item onClick={()=>setLocation('')}>
              Home
            </Breadcrumb.Item>
            {location && location.slice(1).split('/').map((item,i) =>(
              <Breadcrumb.Item onClick={()=>setLocation(location.slice(0,location.indexOf(item)+item.length))} key={i}>
                {item}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Col>
        <Col className="m-1 d-flex flex-row-reverse">
          <NavLink onClick={handleShowDelete} className="m-1">
            <Button variant="secondary">
              DELETE
            </Button>
          </NavLink>
          <NavLink onClick={handleShowUpload} className="m-1">
            <Button variant="secondary">
              UPLOAD
            </Button>
          </NavLink>
          <NavLink onClick={handleShowCreateFolder} className="m-1">
            <Button variant="secondary">
              CREATE FOLDER
            </Button>
          </NavLink>
        </Col>
      </Row>
      <Row>
        {folders && folders.map(folder => (
          <CardIcon key={folder} title={folder.slice(folder.lastIndexOf('/')+1)} icon="folder">
            <FcFolder onClick={()=> setLocation(location+'/'+folder.slice(folder.lastIndexOf('/')+1))} />
          </CardIcon>
        ))}
        {files && files.map(file => (
          <CardIcon key={file} title={file.slice(file.lastIndexOf('/')+1)} icon="file">
            <FcFilmReel />
          </CardIcon>
        ))}
      </Row>
        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Videos and Folders</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           {options.map((option) => (
            <Form.Check
              key={option}
              type="checkbox"
              label={option}
              value={option}
              onChange={handleCheckboxChange}
              checked={selectedOptions.includes(option)}
            />))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              CLOSE
            </Button>
            <Button variant="primary" onClick={()=> {props.deleteItems(selectedOptions); handleCloseDelete()}}>
              DELETE
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showUpload} onHide={handleCloseUpload}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3" controlId="videoUpload">
              <Form.Label className='fw-bold' column sm="2">VideoFile </Form.Label>
              <Col sm="10">
                <Form.Control type="file" accept=".mp4" onChange={handleFileAttachment} />
              </Col>
            </Form.Group>
            <ProgressBar animated now={progress*100} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseUpload}>
              CLOSE
            </Button>
            <Button variant="primary" onClick={()=> {props.uploadVideo(location.slice(1),uploadFile)}}>
              UPLOAD
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showCreateFolder} onHide={handleCloseCreateFolder}>
          <Modal.Header closeButton>
            <Modal.Title>Create Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3" controlId="current">
              <Form.Label className='fw-bold' column sm="2">
                Location:
              </Form.Label>
              <Col sm="10">
                <Form.Control plaintext readOnly defaultValue={'Home/'+location.slice(1)} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="folderName">
              <Form.Label className='fw-bold' column sm="2">Folder Name: </Form.Label>
              <Col sm="10">
                <Form.Control required onChange={(e)=>setFolderName(e.target.value)} value={folderName}/>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCreateFolder}>
              CLOSE
            </Button>
            <Button variant="primary" onClick={()=> {props.createNewFolder(location.slice(1)+'/'+folderName);handleCloseCreateFolder();setFolderName('')}}>
              CREATE
            </Button>
          </Modal.Footer>
        </Modal>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    createNewFolder: (folderName) => dispatch(createNewFolder(folderName)),
    listFilesAndFolders: (location) => dispatch(listFilesAndFolders(location)),
    uploadVideo: (location,uploadFile) => dispatch(uploadVideo(location,uploadFile)),
    deleteItems: (selectedOptions) => dispatch(deleteItems(selectedOptions))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FileManager);