import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import CardIcon from './CardIcon';
import { connect } from 'react-redux';
import { FcBusinessman, FcMultipleDevices, FcLeave, FcLockPortrait, FcCalendar, FcReading } from 'react-icons/fc'

const Dashboard = (props) => { 
  const {category} = props.auth
  const[loading,setLoading] = useState(true)
  useEffect(()=>{
    if(category && category!=='LOGIN'){
      setLoading(false)
    }
  },[category])

  if(!loading){
    return(
      <Container className="p-3">
        <Row>
          {category==='ADMIN' ?
          <>
            <CardIcon to='/employees' title="Employees" icon="people">
              <FcBusinessman />
            </CardIcon>
            <CardIcon to='/inventories' title="Inventories" icon="card-list">
              <FcMultipleDevices />
            </CardIcon>
            <CardIcon to='/leaves' title="Leaves" icon="calendar-x">
              <FcLeave />
            </CardIcon>
          </> : null}
          <>
            <CardIcon to='/myinventory' title="My-Inventories" icon="card-list">
              <FcLockPortrait />
            </CardIcon>
            <CardIcon to='/mycalendar' title="My-Calendar" icon="card-list">
              <FcCalendar />
            </CardIcon>
            <CardIcon to='/my-course' title="My-Courses" icon="card-list">
              <FcReading />
            </CardIcon>
          </>
        </Row>
      </Container>
    )} else {
      return(
      <Container className="d-flex align-items-center justify-content-center" style={{height: '100%'}}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>);
    }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(Dashboard)