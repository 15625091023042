import React from "react";
import EmployeeSummary from "./EmployeeSummary";
import { connect } from 'react-redux'
import { removeEmployee } from "../../store/actions/employeeActions";
import { Table, Container } from "react-bootstrap";

const EmployeeList = (props) => {
  const keys = props.employees ? Object.keys(props.employees) : []
  return(
    <Container className='p-2'>
      <Table striped bordered hover responsive id="employeeTable">
        <thead>
          <tr className="allign-middle">
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th className="d-none d-lg-table-cell">Email</th>
            <th className="d-none d-lg-table-cell">Entity</th>
            <th className="d-none d-lg-table-cell">Designation</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? <tr><td colSpan={3}>'No Records Available'</td></tr>: ''}
          {keys && keys.map(id => {
            return(
              <EmployeeSummary key={id} employee={props.employees[id]} removeEmployee={props.removeEmployee}/>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}


const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeEmployee: (employee) => dispatch(removeEmployee(employee)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(EmployeeList);