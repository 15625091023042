import React, { useState } from "react"
import { Form, Row, Col, Button } from 'react-bootstrap';


const KVRow = (props) => {
  const index = props.index
  const [key,setKey] = useState(props.obj[index].key);
  const [val,setVal] = useState(props.obj[index].val);
  const handleChange = (e) => {
    e.target.id === 'key' ? setKey(e.target.value) : setVal(e.target.value);
    props.setObj({ ...props.obj,
      [index]:{
        ...props.obj[index],
      [e.target.id]: e.target.value
    }})
  }

  return(
    <Row className='p-2'>
      <Col>
        <Form.Control required onChange={handleChange} defaultValue={key} id="key"/>
      </Col>
      <Col>  
        <Form.Control required onChange={handleChange} defaultValue={val} id="val"/>
      </Col>
      <Col xs={1}>
        <Button as='a'
          onClick={()=>props.deleteRow(props.index)} 
          variant='outline-dark'
        >X</Button>
      </Col>
    </Row>
  )
}
export default KVRow;