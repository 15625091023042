const initialState={}

export const groupReducer = (state=initialState, action) => {
  switch(action.type){
    case 'GET_GROUPS':
      return action.groups;
    case 'REMOVE_GROUP':
      return state;
    case 'CLEAR_GROUPS':
      return {};
    default:
      break;
  }
  return state;
}

export default groupReducer;