import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";

const HomePage = () => {
  return(
    <>
      <NavLink to='/lms/filemanager' className="m-1">
        <Button variant="warning">
          MANAGE VIDEOS
        </Button>
      </NavLink>
      <NavLink to='/lms/library' className="m-1">
        <Button variant="warning">
          LIBRARY
        </Button>
      </NavLink>
    </>
  );
}

export default HomePage;