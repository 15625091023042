import { db, storage } from '../../config/fbConfig'
import { ref, child, push, onValue, remove, set } from  'firebase/database'
import { ref as stRef , uploadBytes, getDownloadURL } from 'firebase/storage'

const leadsRef = child(ref(db),'leads')
const storageRef = stRef(storage,'Cards')
export const createLead = (lead) => {
  delete lead.photo;
  return (dispatch, getState) => {
    if(lead.cardUrl.type){
      const reader = new FileReader();
      reader.readAsArrayBuffer(lead.cardUrl)
      reader.onload = (e) => {
        const extension = String(lead.cardUrl.type).split('/')[1];
        const array = new Uint8Array(e.target.result)
        uploadBytes(stRef(storageRef,`${lead.event}/${lead.orgName}-${lead.leadName}.${extension}`),array).then((ss)=>{
          getDownloadURL(ss.ref).then((url)=>{
            lead.cardUrl = url;
            push(child(leadsRef,lead.event),lead).then(()=>{
              dispatch({ type: 'CREATE_LEAD' });
            }).catch((err) => {
              dispatch({ type: 'LEAD_ERROR', err });
            })
          })
        })
      }
    } else {
      push(child(leadsRef,lead.event),lead).then(()=>{
        dispatch({ type: 'CREATE_LEAD' });
      }).catch((err) => {
        dispatch({ type: 'LEAD_ERROR', err });
      })
    }
  }
}

export const fetchLeads = (event) => {
  return (dispatch, getState) => {
    onValue(child(leadsRef,event) , (snapshot)=>{
      const leads = snapshot.val();
      dispatch({ type: 'GET_LEADS', leads })
    })
  }
}

export const fetchEvents = () => {
  return (dispatch, getState) => {
    onValue(leadsRef, (ss)=>{
      const events = Object.keys(ss)
      dispatch({ type: 'FETCH_EVENTS', events})
    })
  }
}

export const removeLead = (event,id) => {
  return (dispatch, getState) => {
    const removeRef = child(child(leadsRef,event),id);
    remove(removeRef).then(()=> {
      dispatch({type: 'REMOVE_LEAD', id})     
    }).catch((err) => {
      dispatch({ type: 'REMOVE_LEAD_ERROR', err })
    })
  }
}

export const updateLead = (event,id,lead) => {
  delete lead.photo;
  return (dispatch, getState) => {
    if(lead.cardUrl.type){
      const reader = new FileReader();
      reader.readAsArrayBuffer(lead.cardUrl)
      reader.onload = (e) => {
        const extension = String(lead.cardUrl.type).split('/')[1];
        const array = new Uint8Array(e.target.result)
        uploadBytes(stRef(storageRef,`${lead.event}/${lead.orgName}-${lead.leadName}.${extension}`),array).then((ss)=>{
          getDownloadURL(ss.ref).then((url)=>{
            lead.cardUrl = url;
            set(child(child(leadsRef,event),id),lead).then(()=>{
              dispatch({ type: 'UPDATE_LEAD' });
            }).catch((err) => {
              dispatch({ type: 'UPDATE_LEAD_ERROR', err });
            })
          })
        })
      }
    } else {
      set(child(child(leadsRef,event),id),lead).then(()=>{
        dispatch({ type: 'UPDATE_LEAD' });
      }).catch((err) => {
        dispatch({ type: 'UPDATE_LEAD_ERROR', err });
      })
    }
  }
}