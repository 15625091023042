import React from "react";
import { Nav } from 'react-bootstrap'
import { Link } from "react-router-dom";

const SignedInLinks = () => {
  return(
    <>
      <Nav.Link as={Link} to='/'>Dashboard</Nav.Link>
      <Nav.Link as={Link} to='/employees'>Employees</Nav.Link>
      <Nav.Link as={Link} to='/inventories'>Inventories</Nav.Link>
      <Nav.Link as={Link} to='/lms'>LMS</Nav.Link>
      <Nav.Link as={Link} to='/groups'>Groups</Nav.Link>
      <Nav.Link as={Link} to='/leads'>Leads</Nav.Link>
    </>
  );
}



export default SignedInLinks;