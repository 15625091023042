const initialState={}

export const chronReducer = (state=initialState, action) => {
  switch(action.type){
    case 'GET_CHRONOLOGIES':
      return action.chronologies;
    case 'CLEAR_CHRONOLOGIES':
      return {};
    default:
      break;
  }
  return state;
}

export default chronReducer;