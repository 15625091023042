import React, {useEffect, useState} from "react";
import LeadsList from "./LeadsList";
import { Container, Row, Col, Button, Tabs, Modal, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Tab } from "bootstrap";
import { exportEmployees } from "../../store/actions/employeeActions";
import { fetchLeads } from "../../store/actions/leadActions";
import { connect } from "react-redux";

const LeadsPage = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [event,setEvent] = useState('Umagine-Chennai-2023');
  const [columns,setColumns] = useState([]);
  const [category,setCategory] = useState("Full Time")
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const index = columns.indexOf(value);
    if (event.target.checked && index === -1) {
      setColumns([...columns, value]);
    } else if (!event.target.checked && index !== -1) {
      setColumns([...columns.slice(0, index), ...columns.slice(index + 1)]);
    }
  };
  const handleChange = (e) => {
    setCategory(e.target.value)
  }

  const handleEventChange = (e) => {
    setEvent(e.target.value)
  }

  useEffect(()=>{
    props.fetchLeads(event);
  },[event])

  return(
    <Container className='p-2'>
      <Row>
        <Col>
          <h2> LEADS </h2>
        </Col>
        <Col>
          <Form.Group as={Row} className="mb-3" controlId="entity"> 
            <Form.Label className='fw-bold' column sm="2">Event</Form.Label>
            <Col sm="10">
              <Form.Select onChange={handleEventChange} value={event}>
                <option value="Umagine-Chennai-2023">Umagine-Chennai-2023</option>
                <option value="Convergence-India-2023">Convergence-India-2023</option>
                <option value="Generic">Generic</option>
              </Form.Select>
            </Col>
          </Form.Group>
        </Col>
        <Col className="m-1 d-flex flex-row-reverse">
          {/* <NavLink onClick={handleShow} className="m-1">
            <Button variant="primary">
              EXPORT
            </Button>
          </NavLink> */}
          <NavLink to='/leads/add' className="m-1">
            <Button variant="success">
              ADD
            </Button>
          </NavLink>
        </Col>
      </Row>
      <Tabs defaultActiveKey='leads' id='leads' className='m-2' justify>
        <Tab eventKey='leads' title='Leads'>
          <LeadsList event={event}/>
        </Tab>
        {/* <Tab eventKey='communicated' title='Communicated'>
          <CommunicatedList event={event}/>
        </Tab>
        <Tab eventKey='prospect' title='Prospect'>
          <ProspectList event={event}/>
        </Tab> */}
      </Tabs>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Download Excel Export</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Check 
            type="checkbox"
            id="empId"
            value="empId"
            label="Employee Id"
            onChange={handleCheckboxChange}
            checked={columns.indexOf("empId") !== -1}
          />
          <Form.Check 
            type="checkbox"
            id="firstName"
            value="firstName"
            label="First Name"
            onChange={handleCheckboxChange}
            checked={columns.indexOf("firstName") !== -1}
          />
          <Form.Check 
            type="checkbox"
            id="lastName"
            value="lastName"
            label="Last Name"
            onChange={handleCheckboxChange}
            checked={columns.indexOf("lastName") !== -1}
          />
          <Form.Check 
            type="checkbox"
            id="designation"
            value="designation"
            label="Designation"
            onChange={handleCheckboxChange}
            checked={columns.indexOf("designation") !== -1}
          />
          <Form.Select required onChange={handleChange} >
            <option value="Full Time">Full Time</option>
            <option value="Contractor">Contractor</option>
            <option value="Trainee">Trainee</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={()=> {exportEmployees(columns,category);handleClose()}}>
            Export
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLeads: (event) => dispatch(fetchLeads(event))
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsPage);