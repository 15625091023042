import React, { useState } from 'react'
import { connect } from 'react-redux'
import { createRegistrant } from '../../../store/actions/registrantActions';
import { Container, Form, Row, Col, Button, Spinner, Figure } from 'react-bootstrap';

const CreateRegistrant = (props) => {
  const [disabled,setDisabled] = useState(false);
  const [registrant,setRegistrant] = useState({
    entity: 'Exgen Systems LLC',
    photo: '',
    imageUrl: '',
    firstName: '',
    lastName: '',
    dob: '2000-01-01',
    address: '',
    doj: '2022-01-01',
    designation: '',
    mobile: '',
    empId:'',
    personalEmail: '',
    isAdmin: false,
    isBusiness: false
  })

  const handleChange = (e) => {
    setRegistrant({ ...registrant,
      [e.target.id]: e.target.value
    })
  }

  const handlePhotoUpload = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setRegistrant({
        ...registrant,
        photo: reader.result,
        imageUrl: e.target.files[0]
      })
    }
  }

  const reset = () => {
    setRegistrant({
      entity: 'Exgen Systems LLC',
      photo: '',
      imageUrl: '',
      firstName: '',
      lastName: '',
      dob: '2000-01-01',
      address: '',
      doj: '2022-01-01',
      designation: '',
      mobile: '',
      empId: '',
      personalEmail: '',
      isAdmin: false,
      isBusiness: false
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    props.createRegistrant(registrant)
    reset();
  }
  
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
      <h3 className='grey-text text-darken-3'>Register Employee</h3>
      <Form.Group as={Row} className="mb-3" controlId="photo">
        <Form.Label className='fw-bold' column sm="2">
          Upload Photo
        </Form.Label>
        <Col sm="10">
          <Figure.Image src={registrant.photo} alt='Photo' width={170} height={170}/>
          <Form.Control required type='file' onChange={handlePhotoUpload} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="firstName">
        <Form.Label className='fw-bold' column sm="2">
          First Name
        </Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={registrant.firstName} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="lastName">
        <Form.Label className='fw-bold' column sm="2">Last Name</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={registrant.lastName}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="dob">
        <Form.Label className='fw-bold' column sm="2">Date Of Birth</Form.Label>
        <Col sm="10">
          <Form.Control required type="date" onChange={handleChange} value={registrant.dob}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="address">
        <Form.Label className='fw-bold' column sm="2">Address</Form.Label>
        <Col sm="10">
          <Form.Control required as="textarea" row={3} onChange={handleChange} value={registrant.address}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="doj">
        <Form.Label className='fw-bold' column sm="2">Date Of Joining</Form.Label>
        <Col sm="10">
          <Form.Control required type="date" onChange={handleChange} value={registrant.doj}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="designation">
        <Form.Label className='fw-bold' column sm="2">Designation</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={registrant.designation}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="mobile">
        <Form.Label className='fw-bold' column sm="2">Mobile</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={registrant.mobile}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="personalEmail">
        <Form.Label className='fw-bold' column sm="2">Personal Mail</Form.Label>
        <Col sm="10">
          <Form.Control required type='email' onChange={handleChange} value={registrant.personalEmail}/>
        </Col>
      </Form.Group>
      <Form.Group>
        <Button className='m-1' variant="success" type="submit">
          {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Register'}
        </Button>
        <Button className='m-1' variant="warning" onClick={()=> reset()}>
          Reset
        </Button>
      </Form.Group>
    </Form>
  </Container>
)}

const mapDispatchToProps = (dispatch) => {
  return {
    createRegistrant: (registrant) => dispatch(createRegistrant(registrant))
  }
}

export default connect(null, mapDispatchToProps)(CreateRegistrant);
