const initialState={}

export const registrantReducer = (state=initialState, action) => {
  switch(action.type){
    case 'GET_REGISTRANTS':
      return action.registrants;
    case 'REMOVE_REGISTRANT':
      return state;
    case 'CLEAR_REGISTRANTS':
      return {};
    default:
      break;
  }
  return state;
}

export default registrantReducer;