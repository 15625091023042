import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { connect, useSelector } from 'react-redux';
import { Container, Form, Row, Col, Button, Table, Figure, Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import LeaveList from '../calendar/LeaveList';
import { createLeave } from '../../store/actions/leaveActions';

const EmployeeDetails = (props) => {
  const { id } = useParams();
  const leaves = (props.leaves && props.leaves[id]) || []
  const applied = Object.keys(leaves).filter((fid)=> leaves[fid].approved===false)
  const approved = Object.keys(leaves).filter((fid)=> leaves[fid].approved===true)
  const chronologies = props.chronologies;
  const chrons = chronologies && Object.keys(chronologies).map((k)=>{
    return chronologies[k].filter((chron)=>chron.empId==id)
  }).filter((arr)=>arr.length>0).flat()
  const employee = useSelector((state) => state.employees[id])
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [leave,setLeave] = useState({
    begin: new Date().toISOString().substring(0,10),
    end: new Date().toISOString().substring(0,10),
    duration: 0.5,
    month: '',
    approved: true,
    reason: ''})
  

  const handleChange = (e) => {
    setLeave({ ...leave,
      [e.target.id]: e.target.value
    })
  }

  const reset = () => {
    setLeave({
      begin: new Date().toISOString().substring(0,10),
      end: new Date().toISOString().substring(0,10),
      month: '', 
      duration: 0.5,
      approved: true,
      reason: ''})
  }

  const handleSubmit = (e) => {
    props.createLeave(leave,id)
    reset();
    handleClose();
  }

  if(employee){
    return (
      <>
        <Row>
          <Col>
            <Container>
              <h3 className='grey-text text-darken-3'>Employee Details</h3>
              <Form>
                <Figure.Image src={employee.imageUrl} alt='Photo' width={170} height={170}/>
                <Form.Group as={Row} className="mb-3" controlId="empId">
                  <Form.Label className='fw-bold' column sm="2">
                    Employee ID
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly defaultValue={employee.empId} />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="category">
                  <Form.Label className='fw-bold' column sm="2">
                    Category
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly defaultValue={employee.category} />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="isAdmin">
                  <Form.Label className='fw-bold' column sm="2">
                    Is Admin
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly defaultValue={employee.isAdmin ? 'Yes' : 'No'} />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="firstName">
                  <Form.Label className='fw-bold' column sm="2">
                    First Name
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly defaultValue={employee.firstName} />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="lastName">
                  <Form.Label className='fw-bold' column sm="2">Last Name</Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly value={employee.lastName}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="dob">
                  <Form.Label className='fw-bold' column sm="2">Date Of Birth</Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly value={employee.dob}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="address">
                  <Form.Label className='fw-bold' column sm="2">Address</Form.Label>
                  <Col sm="10">
                    <Form.Control as="textarea" rows={4} plaintext readOnly value={employee.address}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="entity"> 
                  <Form.Label className='fw-bold' column sm="2">Entity</Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly value={employee.entity}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="doj">
                  <Form.Label className='fw-bold' column sm="2">Date Of Joining</Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly value={employee.doj}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="designation">
                  <Form.Label className='fw-bold' column sm="2">Designation</Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly value={employee.designation}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="email">
                  <Form.Label className='fw-bold' column sm="2">Email</Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly value={employee.email}/>
                  </Col>
                </Form.Group> 
                <Form.Group as={Row} className="mb-3" controlId="mobile">
                  <Form.Label className='fw-bold' column sm="2">Mobile</Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly value={employee.mobile}/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="personalEmail">
                  <Form.Label className='fw-bold' column sm="2">Personal Mail</Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly value={employee.personalEmail}/>
                  </Col>
                </Form.Group>
                <Button variant="secondary" onClick={()=> navigate('/employees')}>
                  BACK
                </Button>
              </Form>
            </Container>
          </Col>
          <Col>
            <Container>
              <Row>
                <h3 className='grey-text text-darken-3'>Inventory Details</h3>
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {chrons && chrons.length > 0 && chrons.map((chron,i)=>{
                      return(
                        <tr key={i}>
                          <td>{chron.chronId}</td>
                          <td>{props.inventories[chron.chronId].name}</td>
                          <td>{chron.start}</td>
                          <td>{chron.end}</td>
                          <td>
                            <NavLink className="btn btn-primary m-1 rounded-circle" to={`/inventory/${chron.chronId}`}>
                              <i className="bi bi-eye"></i>
                            </NavLink>
                          </td>
                        </tr>
                      );
                    })
                    }
                  </tbody>
                </Table>
              </Row>
              <Row>
                <Container>
                  <h3 className='grey-text text-darken-3'>Applied Leaves</h3>
                  <LeaveList filterLeaves={applied} uid={id}/>
                </Container>
              </Row>
              <Row>
                <Container>
                  <Row>
                    <Col>
                      <h3 className='grey-text text-darken-3'>Approved Leaves</h3>
                    </Col>
                    <Col className='m-1 d-flex flex-row-reverse'>
                      <Button onClick={handleShow}>Add</Button>
                    </Col>
                  </Row>
                  <LeaveList filterLeaves={approved} uid={id}/>
                </Container>
              </Row>
            </Container>
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Leave?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Col} className="mb-3" controlId="begin">
              <Form.Label className='fw-bold' column sm="2">Begin Date</Form.Label>
              <Col sm="10">
                <Form.Control required type="date" onChange={handleChange} value={leave.begin}/>
              </Col>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="end">
              <Form.Label className='fw-bold' column sm="2">End Date</Form.Label>
              <Col sm="10">
                <Form.Control required type="date" min={leave.begin} onChange={handleChange} value={leave.end}/>
              </Col>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="duration">
              <Form.Label className='fw-bold' column sm="2">Duration (Multiples of 0.5 days)</Form.Label>
              <Col sm="10">
                <Form.Control required type="number" step={0.5} onChange={handleChange} value={leave.duration}/>
              </Col>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="reason"> 
              <Form.Label className='fw-bold' column sm="2">Reason</Form.Label>
              <Col sm="10">
                <Form.Control required onChange={handleChange} value={leave.reason}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="month"> 
            <Form.Label className='fw-bold' column sm="2">Month: </Form.Label>
            <Col sm="5">
              <Form.Control required type="month" onChange={handleChange} value={leave.month}/>
            </Col>
          </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Exit
            </Button>
            <Button variant="danger" onClick={handleSubmit}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  } else {
    return(
      <div className='container'>
        Loading....
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    createLeave : (leave,uid) => dispatch(createLeave(leave,uid))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(EmployeeDetails);
