import React from "react";
import { NavLink } from "react-router-dom";
import DeleteInventory from './DeleteInventory'


const InventorySummary = ({inventory, removeInventory}) => {
  return(
    <tr className="align-middle">
      <td>{inventory.invId}</td>
      <td>{inventory.name}</td> 
      <td className="d-none d-lg-table-cell">{inventory.tags && inventory.tags.map(({value})=>value).toString()}</td>
      <td>
        <NavLink className="btn btn-success m-1 rounded-circle" to={`/inventory/${inventory.invId}`}>
          <i className="bi bi-eye"></i>
        </NavLink>
        <NavLink className="btn btn-primary m-1 rounded-circle" to={`/inventory/edit/${inventory.invId}`}>
          <i className="bi bi-pencil"></i>
        </NavLink>
        <DeleteInventory inventory={inventory} removeInventory={removeInventory}/>
        <NavLink className="btn btn-primary m-1 rounded-circle" to={`/inventory/chronology/${inventory.invId}`}>
          <i className="bi bi-hourglass-split"></i>
        </NavLink>
      </td>
    </tr>
  );
}

export default InventorySummary;