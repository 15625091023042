import authReducer from "./reducers/authReducer";
import registrantReducer from "./reducers/registrantReducer";
import inventoryReducer from "./reducers/inventoryReducer";
import tagReducer from './reducers/tagReducer';
import chronReducer from './reducers/chronReducer';
import { combineReducers } from "redux";
import { createStore, applyMiddleware } from "redux";
import { thunk } from 'redux-thunk'
import inviteeReducer from "./reducers/inviteeReducer";
import leadReducer from "./reducers/leadReducer";
import employeeReducer from "./reducers/employeeReducer";
import leaveReducer from "./reducers/leaveReducer";
import mfaReducer from "./reducers/mfaReducer"
import toastReducer from "./reducers/toastReducer";
import lmsReducer from "./reducers/lmsReducer";
import groupReducer from "./reducers/groupReducer";
import securityReducer from "./reducers/securityReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  registrants: registrantReducer,
  inventories: inventoryReducer,
  tags: tagReducer,
  chronologies: chronReducer,
  invitees: inviteeReducer,
  leads: leadReducer,
  employees: employeeReducer,
  leaves: leaveReducer,
  mfa: mfaReducer,
  toastMessage: toastReducer,
  lms: lmsReducer,
  groups: groupReducer,
  security: securityReducer
})

const store = createStore(rootReducer,applyMiddleware(thunk))

export default store;