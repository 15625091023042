const initialState={}

export const tagReducer = (state=initialState, action) => {
  switch(action.type){
    case 'GET_TAGS':
      return action.tags;
    default:
      break;
  }
  return state;
}

export default tagReducer;