import React from "react";
import { NavLink } from "react-router-dom";
import DeleteLead from './DeleteLead'


const LeadSummary = ({lead, removeLead, id, event}) => {
  return(
    <tr className="align-middle">
      <td>{lead.orgName}</td>
      <td>{lead.leadName}</td> 
      <td>{lead.context}</td>
      <td>
        <NavLink className="btn btn-success m-1 rounded-circle" to={`/leads/${event}/${id}`}>
          <i className="bi bi-eye"></i>
        </NavLink>
        <NavLink className="btn btn-primary m-1 rounded-circle" to={`/leads/edit/${event}/${id}`}>
          <i className="bi bi-pencil"></i>
        </NavLink>
        <DeleteLead lead={lead} id={id} removeLead={removeLead}/>
      </td>
    </tr>
  );
}

export default LeadSummary;