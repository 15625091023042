import { db } from '../../config/fbConfig'
import { ref, child, set, onValue } from  'firebase/database'

const chronsRef = child(ref(db),'chronologies')
export const createChronology = (chronology,invId) => {
  const chronRef = child(chronsRef,invId);
  return (dispatch, getState) => {
    set(chronRef,{
      ...chronology
    }).then(()=>{
      dispatch({ type: 'CREATE_CHRONOLOGY', chronology});
    }).catch((err) => {
      dispatch({ type: 'CREATE_CHRONOLOGY_ERROR', err });
    })
  }
}

export const fetchChronologies = () => {
  return (dispatch, getState) => {
    onValue(chronsRef , (snapshot)=>{
      const chronologies = snapshot.val();
      dispatch({ type: 'GET_CHRONOLOGIES', chronologies })
    })
  }
}