import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { updateEmployee } from '../../store/actions/employeeActions'
import { updateUserPassword } from '../../store/actions/authActions'
import { useNavigate } from 'react-router-dom'
import { Container, Form, Row, Col, Button, Spinner, Figure } from 'react-bootstrap';

const EditMyProfile = (props) => {
  const { auth, employees } = props;
  const[loading,setLoading] = useState(true)
  const [employee,setEmployee] = useState({});
  useEffect(()=>{
    if(auth !== null && Object.keys(employees).length>0){
      setLoading(false)
      setEmployee(employees[auth.user.uid])
    }
  }, [auth, employees])
  const [password,setPassword] = useState({old:'',new:'',reNew:''})
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    props.updateEmployee(employee)
    navigate('/')
  }

  const handlePasswordUpdate = (e) => {
    e.preventDefault();
    props.updateUserPassword(password);
  }

  const resetPassword = () => {
    setPassword({old:'',new:'', reNew:''})
  }

  const handleChange = (e) => {
    setEmployee({...employee,
      [e.target.id]: e.target.value
    })
  }

  const handlePasswordChange = (e) => {
    setPassword({...password,
      [e.target.id]: e.target.value
    })
  }

  if(!loading){
    return (
      <Container>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
            <h3 className='grey-text text-darken-3'>Update Profile</h3>
            <Figure.Image src={employee.imageUrl} alt='Photo' width={170} height={170}/>
            <Form.Group as={Row} className="mb-3" controlId="empId">
              <Form.Label className='fw-bold' column sm="2">Employee ID</Form.Label>
              <Col sm="10">
                <Form.Control plaintext readOnly value={employee.empId} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="category">
              <Form.Label className='fw-bold' column sm="2">Category</Form.Label>
              <Col sm="10">
                <Form.Control plaintext readOnly value={employee.category} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="email">
              <Form.Label className='fw-bold' column sm="2">Email</Form.Label>
              <Col sm="10">
                <Form.Control type='email' plaintext readOnly value={employee.email} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="entity"> 
              <Form.Label className='fw-bold' column sm="2">Entity</Form.Label>
              <Col sm="10">
                <Form.Control type="text" readOnly plaintext value={employee.entity}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="doj">
              <Form.Label className='fw-bold' column sm="2">Date Of Joining</Form.Label>
              <Col sm="10">
                <Form.Control type="date" readOnly plaintext value={employee.doj}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="designation">
              <Form.Label className='fw-bold' column sm="2">Designation</Form.Label>
              <Col sm="10">
                <Form.Control readOnly plaintext value={employee.designation}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="firstName">
              <Form.Label className='fw-bold' column sm="2">
                First Name
              </Form.Label>
              <Col sm="10">
                <Form.Control required onChange={handleChange} value={employee.firstName} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="lastName">
              <Form.Label className='fw-bold' column sm="2">Last Name</Form.Label>
              <Col sm="10">
                <Form.Control required onChange={handleChange} value={employee.lastName} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="dob">
              <Form.Label className='fw-bold' column sm="2">Date Of Birth</Form.Label>
              <Col sm="10">
                <Form.Control required type="date" onChange={handleChange} value={employee.dob}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="address">
              <Form.Label className='fw-bold' column sm="2">Address</Form.Label>
              <Col sm="10">
                <Form.Control required as="textarea" row={3} onChange={handleChange} value={employee.address} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="mobile">
              <Form.Label className='fw-bold' column sm="2">Mobile</Form.Label>
              <Col sm="10">
                <Form.Control required onChange={handleChange} value={employee.mobile}/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="personalEmail">
              <Form.Label className='fw-bold' column sm="2">Personal Mail</Form.Label>
              <Col sm="10">
                <Form.Control required onChange={handleChange} value={employee.personalEmail}/>
              </Col>
            </Form.Group>
            <Form.Group>
              <Button className='m-1' variant="warning" type="submit">
                UPDATE
              </Button>
              <Button className='m-1' variant="secondary" onClick={()=> navigate('/')}>
                CANCEL
              </Button>
            </Form.Group>
            </Form>
          </Col>
          <Col>
            <Form onSubmit={handlePasswordUpdate}>
              <h3 className='grey-text text-darken-3'>Update Password</h3>
              <Form.Group as={Row} className="mb-3" controlId="old">
                <Form.Label className='fw-bold' column sm="2">Old:</Form.Label>
                <Col sm="10">
                  <Form.Control required type='password' onChange={handlePasswordChange} value={password.old} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="new">
                <Form.Label className='fw-bold' column sm="2">New:</Form.Label>
                <Col sm="10">
                  <Form.Control required type='password' onChange={handlePasswordChange} value={password.new} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="reNew">
                <Form.Label className='fw-bold' column sm="2">Retype New:</Form.Label>
                <Col sm="10">
                  <Form.Control required type='password' onChange={handlePasswordChange} value={password.reNew} />
                </Col>
              </Form.Group>
              <Form.Group>
                <Button className='m-1' variant="warning" type="submit">
                  UPDATE
                </Button>
                <Button className='m-1' variant="secondary" onClick={()=> resetPassword()}>
                  CANCEL
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    )
  } else {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{height: '100%'}}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateEmployee: (employee) => dispatch(updateEmployee(employee)),
    updateUserPassword: (password) => dispatch(updateUserPassword(password))
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMyProfile);
