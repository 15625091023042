import { db, functions, storage } from '../../config/fbConfig'
import { ref, child, set, onValue, remove } from  'firebase/database'
import { httpsCallable } from 'firebase/functions'
import { ref as stRef, uploadBytes, getDownloadURL } from 'firebase/storage'

const employeesRef = child(ref(db),'employees')
const storageRef = stRef(storage,'Profiles')
export const createEmployee = (employee) => {
  const employeeRef = child(employeesRef,employee.id);
  const activateAccount = httpsCallable(functions,'activateAccountFunction')
  return (dispatch, getState) => {
    set(employeeRef,{
      ...employee
    }).then(()=>{
      activateAccount({uid: employee.id}).then(()=>{
        const sendApprovalEmployeeEmail = httpsCallable(functions, 'sendApprovalEmployeeEmailFunction');
        sendApprovalEmployeeEmail({email:employee.email,name:employee.firstName+' '+employee.lastName}).then(()=>{
          dispatch({ type: 'CREATE_EMPLOYEE', employee});
        })
      })
    }).catch((err) => {
      dispatch({ type: 'CREATE_EMPLOYEE_ERROR', err });
    })
    const registrant = getState().registrants[employee.id]
    remove(child(child(ref(db),'registrants'),employee.id)).then(()=> {
      dispatch({type: 'REMOVE_REGISTRANT', registrant})
    }).catch((err) => {
      dispatch({ type: 'REMOVE_REGISTRANT_ERROR', err })
    })
  }
}

export const updateEmployee = (employee) => {
  delete employee.photo
  const reader = new FileReader();
  const employeeRef = child(employeesRef,employee.id);
  return (dispatch, getState) => {
    if(employee.imageUrl.type){
      reader.readAsArrayBuffer(employee.imageUrl)
      const extension = String(employee.imageUrl.type).split('/')[1];
      reader.onload = (e) => {
        const array = new Uint8Array(e.target.result)
        uploadBytes(stRef(storageRef,`${employee.id}.${extension}`),array).then((ss)=>{
          getDownloadURL(ss.ref).then((url)=>{
            employee.imageUrl = url;
            set(employeeRef,{
              ...employee
            }).then(()=>{
              dispatch({ type: 'CREATE_EMPLOYEE', employee});
            }).catch((err) => {
              dispatch({ type: 'CREATE_EMPLOYEE_ERROR', err });
            })
          })
        })
      }
    } else {
      set(employeeRef,{
        ...employee
      }).then(()=>{
        dispatch({ type: 'CREATE_EMPLOYEE', employee});
      }).catch((err) => {
        dispatch({ type: 'CREATE_EMPLOYEE_ERROR', err });
      })
    }
  }
}

export const fetchEmployees = () => {
  return (dispatch, getState) => {
    onValue(employeesRef , (snapshot)=>{
      const employees = snapshot.val();
      dispatch({ type: 'GET_EMPLOYEES', employees })
    })
  }
}

export const getEmployee = (uid) => {
  return (dispatch, getState) => {
    onValue(child(employeesRef,uid) , (snapshot)=>{
      const employee = {[uid] : snapshot.val()};
      dispatch({ type: 'GET_EMPLOYEE', employee })
    })
  }
}

export const removeEmployee = (employee) => {
  const exEmployeesRef = child(child(ref(db),'exEmployees'),employee.id)
  return (dispatch, getState) => {
    const removeRef = child(employeesRef,employee.id);
    const deleteAccount = httpsCallable(functions,'deleteAccountFunction')
    remove(removeRef).then(()=> {
      deleteAccount({uid:employee.id}).then(()=>{
        dispatch({type: 'REMOVE_EMPLOYEE', employee})
      })
    }).catch((err) => {
      dispatch({ type: 'REMOVE_EMPLOYEE_ERROR', err })
    })
    set(exEmployeesRef,employee);
  }
}

export const exportEmployees = (columns,category) => {
  const exportEmployees = httpsCallable(functions,'exportDataToExcel')
  exportEmployees({ref:'employees',columns,filename:'Employees.xlsx',category}).then((result)=>{
    const link = document.createElement('a');
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result.data.file}`;
    link.download = result.data.filename;
    link.click();
  })
}

export const filterEmployees = (search) => {
  return (dispatch, getState) => {
    onValue(employeesRef , (snapshot)=>{
      const employees = Object.fromEntries(Object.entries(snapshot.val()).filter(([eid,val])=> String(val.firstName+' '+val.lastName).toLowerCase().includes(search.toLowerCase())))
      dispatch({ type: 'GET_EMPLOYEES', employees })
    })
  }
}