import React, {useEffect, useState} from "react";
import RegistrantList from "./registered/RegistrantList";
import InviteeList from "./invited/InviteeList";
import EmployeeList from "./EmployeeList";
import { Container, Row, Col, Button, Tabs, Modal, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Tab } from "bootstrap";
import { exportEmployees, fetchEmployees } from "../../store/actions/employeeActions";
import { connect } from "react-redux";
import { filterEmployees } from "../../store/actions/employeeActions";

const EmployeesPage = (props) => {
  const [show, setShow] = useState(false);
  const [search,setSearch] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [columns,setColumns] = useState([]);
  const [type,setType] = useState("Full Time")
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const index = columns.indexOf(value);
    if (event.target.checked && index === -1) {
      setColumns([...columns, value]);
    } else if (!event.target.checked && index !== -1) {
      setColumns([...columns.slice(0, index), ...columns.slice(index + 1)]);
    }
  };
  const handleChange = (e) => {
    setType(e.target.value)
  }
  useEffect(()=>{
    if(search.length){
      props.filterEmployees(search)
    } else {
      props.fetchEmployees()
    }
  },[search])

  return(
    <Container className='p-2'>
      <Row>
        <Col>
          <h2> EMPLOYEES ({Object.keys(props.employees).length}) </h2>
        </Col>
        <Col className="m-1 d-flex flex-row-reverse">
          <NavLink onClick={handleShow} className="m-1">
            <Button variant="secondary">
              EXPORT
            </Button>
          </NavLink>
          <NavLink to='/invite' className="m-1">
            <Button variant="warning">
              INVITE
            </Button>
          </NavLink>
          <Form.Group as={Row} className="mb-3" controlId="firstName">
            <Col>
              <Form.Control placeholder="search" onChange={(e)=>setSearch(e.target.value)} value={search} />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Tabs defaultActiveKey='employees' id='employess' className='m-2' justify>
        <Tab eventKey='employees' title='Employees'>
          <EmployeeList />
        </Tab>
        <Tab eventKey='registered' title='Registered'>
          <RegistrantList />
        </Tab>
        <Tab eventKey='invitees' title='Invited'>
          <InviteeList />
        </Tab>
      </Tabs>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Download Excel Export</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Check 
            type="checkbox"
            id="empId"
            value="empId"
            label="Employee Id"
            onChange={handleCheckboxChange}
            checked={columns.indexOf("empId") !== -1}
          />
          <Form.Check 
            type="checkbox"
            id="firstName"
            value="firstName"
            label="First Name"
            onChange={handleCheckboxChange}
            checked={columns.indexOf("firstName") !== -1}
          />
          <Form.Check 
            type="checkbox"
            id="lastName"
            value="lastName"
            label="Last Name"
            onChange={handleCheckboxChange}
            checked={columns.indexOf("lastName") !== -1}
          />
          <Form.Check 
            type="checkbox"
            id="designation"
            value="designation"
            label="Designation"
            onChange={handleCheckboxChange}
            checked={columns.indexOf("designation") !== -1}
          />
          <Form.Select required onChange={handleChange} >
            <option value="Full Time">Full Time</option>
            <option value="Contractor">Contractor</option>
            <option value="Trainee">Trainee</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={()=> {exportEmployees(columns,type);handleClose()}}>
            Export
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    filterEmployees: (search) => dispatch(filterEmployees(search)),
    fetchEmployees: () => dispatch(fetchEmployees())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(EmployeesPage);