const initialState={}

export const inviteeReducer = (state=initialState, action) => {
  switch(action.type){
    case 'INVITEE_ERROR':
      return {
        ...state,
        inviteeError: action.err.message,
      }
    case 'GET_INVITEES':
      return action.invitees;
    case 'REMOVE_INVITEE':
      return state;
    case 'CLEAR_INVITEE':
      return {};
    default:
      break;
  }
  return state;
}

export default inviteeReducer;