const initialState={}

export const toastReducer = (state=initialState, action) => {
  switch(action.type){
    case 'CLEAR_TOAST':
      return null;
    case 'CREATE_INVITEE':
      return { message:'Invitee Created',variant: 'success' }
    case 'REMOVE_INVITEE':
      return { message:'Invitee Deleted',variant: 'warning' }
    default:
      break;
  }
  return state;
}

export default toastReducer;