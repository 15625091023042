const initialState = {videoList : {
        0: ["Introduction","Introduction.mp4","video"],
        1: ["Evading the Phinishing net","Phishing.mp4","video"],
        2: ["Ransomeware the cyber blackmail","Ransomeware.mp4","video"],
        3: ["Module 2 Quiz",null,"quiz"],
        4: ["The Science of Strong Passwords","Strong Password.mp4","video"],
        5: ["Social Engineering and Social Media Security","Social Engineering.mp4","video"],
        6: ["Module 3 Quiz",null,"quiz"],
        7: ["Cyber Security on Cloud Platforms","Cybersecurity On Cloud Platforms.mp4","video"],
        8: ["Maintaining Security Off The Desk and Work From Home","Maintaining Secuirty off Desk.mp4","video"],
        9: ["Module 4 Quiz",null,"quiz"],
        10: ["Conclusion", "Conclusion.mp4","video"],
        11: ["Final Quiz",null,"quiz"]
    },
    quizList: {
        3: {0: ["What type of cyberattack did the colonial pipeline experience in 2021?",["Phishing","Ransomware","Denial-of-services (DOS)","Data Breach"],"Ransomware"],
            1: ["What was the primary impact of the DarkSide hackers attack on Colonial pipeline in 2021?",["Fuel Shortages due to pipeline damage","Compromised billing systems leading to undelivered fuel","Compromised billing systems, but fuel delivery was unaffected","Physical destruction of the pipeline"],"Compromised billing systems, but fuel delivery was unaffected"],
            2: ["What is the main characteristic of 'spear Phishing' as opposed to regular phishing?",["Targets high level executives","Targets a specific individual","Sent via SMS","Uses voice calls"],"Targets a specific individual"],
            3: ["What does the term 'Phishing' in cybersecurity resemble?",["A technical Error","A fishing tactic","A software bug","A hardware malfunction"],"A fishing tactic"]
            },
        6: {0: ["What should be regularly checked to ensure the security of social media accounts?",["Active session logins","Number of followers","Post likes and shares","Number of direct messages"],"Active session logins"],
            1: ["What is 'Impersonation' in the context of social engineering attack?",["Creating a fabricated Scenario to deceive the target","Physically following targets or their activities","The attacker posing as a trusted figure to extract information","The use of fake emergencies to solicit money"],"The attacker posing as a trusted figure to extract information"],
            2: ["Which method involves trying every possible combination to crack a password?",["Phishing","Social Engineering","Dictionary Attack","Brute-force Method"],"Brute-force Method"],
            3: ["What is the primary reason weak passwords like 'name@123' or '12345678' are vulnerable?",["They are too long and complex","They contain special characters","They are easy to guess and predict","They are frequently changed"],"They are easy to guess and predict"]
            },
        9: {0: ["How should sensitive documents be handled when no longer needed?",["Thrown away with regular trash","Left at a shared printer","Shredded before disposing","Stored indefinitely"],"Shredded before disposing"],
            1: ["What is recommended for securing devices like laptops, tablets, and smartphones",["Leaving them in public places when not in use","Securing them with strong passwords and biometric locks","Sharing the device passwords with trusted colleagues","Using the same password for all devices"],"Securing them with strong passwords and biometric locks"],
            2: ["What does IAM stand for in cloud computing",["Internet and Access Management","Identity and Access Management","Integrated Application Management","Internal and External Management"],"Identity and Access Management"],
            3: ["What are the three types of cloud offerings mentioned in the text",["Public Cloud, Private Cloud, and Hybrid Cloud","Local Cloud, Distributed Cloud, and Mixed Cloud","Single-Tenant Cloud, Multi-Tenant Cloud, and Combined Cloud","Open Cloud, Closed Cloud, and Semi-Closed Cloud"],"Public Cloud, Private Cloud, and Hybrid Cloud"]
            },
        11:{0: ["What was the first ever widespread phishing attack targeting?",["Yahoo! users","Equifax customers","AOL Dial-Up internet users","Online banking customers"],"AOL Dial-Up internet users"],
            1: ["Which of the following is NOT a type of phishing attack mentioned in the text?",["Whaling","Smishing","Vishing","Fishing"],"Fishing"],
            2: ["What should you do if you suspect a phishing attempt?",["Ignore the email","Respond to the sender","Report it to the IT department or relevant authorities","Delete the email"],"Report it to the IT department or relevant authorities"],
            3: ["Which of the following is NOT a type of ransomware mentioned in the text?",["Encrypting ransomware","Locker ransomware","Scareware","Spyware"],"Spyware"],
            4: ["What is the general advice from law enforcement and cybersecurity experts in case of a ransomware attack?",["Negotiate with the attackers","Pay the ransom immediately","Try to remove the ransomware yourself","Do not pay the ransom"],"Do not pay the ransom"],
            5: ["What is a key measure to reduce the severity of ransomware attacks?",["Regularly changing passwords","Keeping software up to date","Regular backups of critical data","Using strong antivirus solutions"],"Regular backups of critical data"],
            6: ["What is a recommended length for a strong password?",["At least 6 characters","At least 8 characters","At least 10 characters","At least 12 characters"],"At least 12 characters"],
            7: ["What is NOT a recommended practice for creating strong passwords?",["Using dictionary words","Using a mix of characters, numbers, and symbols","Creating a long password","Using phrases instead of a single word"],"Using dictionary words"],
            8: ["What is the role of a password manager in digital security?",["To frequently change passwords","To generate and store strong passwords","To encrypt emails and messages","To detect and prevent cyber attacks"],"To generate and store strong passwords"],
            9: ["Which of the following is NOT a recommended practice for securing your social media presence?",["Using public Wi-Fi for social media","Using multi-factor authentication", "Controlling who can tag or mention you in posts","Reviewing and revoking access to suspicious third-party apps"],"Using public Wi-Fi for social media"],
            10: ["How can one enhance security while using Slack in an organizational setting?",["Use Enterprise Key Management","Regularly change profile pictures","Share sensitive information in public channels","Use the same password for all tools"],"Use Enterprise Key Management"],
            11: ["What is the key message regarding dealing with social engineering attacks?",["Completely avoid using social media","Remain mindful, calm, and vigilant","Only use social media for personal purposes","Share all types of content freely"],"Remain mindful, calm, and vigilant"],
            12: ["What is the purpose of a Virtual Private Cloud (VPC)?",["To provide a public platform for cloud computing","To offer a secure, isolated private cloud within a public cloud","To connect different cloud services","To manage user data on cloud platforms"],"To offer a secure, isolated private cloud within a public cloud"],
            13: ["What principle should guide the granting of user permissions in cloud computing?",["Maximum privilege principle","Least privilege principle","Equal privilege principle","Role-based privilege principle"],"Least privilege principle"],
            14: ["What is a key responsibility of clients in the Shared Responsibility Model for cloud security?",["Securing the cloud infrastructure","Protecting their data and applications","Providing network connections","Managing the cloud service provider"],"Protecting their data and applications"],
            15: ["What is a recommended practice when using public Wi-Fi networks for company-related work?",["Avoid using a VPN","Use a VPN if necessary","Share sensitive information freely","Use public Wi-Fi without precautions"],"Use a VPN if necessary"],
            16: ["What should be done with company hardware before disposal or repurposing?",["Sell it as is for extra revenue","Ensure all data is wiped clean","Donate it without any modifications","Use it for personal purposes"],"Ensure all data is wiped clean"],
            17: ["What should employees working from home do with their work devices?",["Use them for personal activities", "Lock them when not in use", "Leave them in open areas","Connect them to any available network"],"Lock them when not in use"],
            18: ["What is an essential aspect of maintaining security in digital interactions?",["Frequent password changes only","Proactive awareness and online behavior management","Solely relying on antivirus software","Sharing passwords with trusted colleagues"],"Proactive awareness and online behavior management"],
            19: ["What underlines the importance of the human element in cybersecurity?",["Focusing only on technical measures","Ignoring security policies and protocols","Embracing everyday practices like securing devices and cautious behavior","Solely depending on cloud security"],"Embracing everyday practices like securing devices and cautious behavior"]
            }
    },
    urls:{}
}
export const securityReducer = (state=initialState, action) => {
    switch(action.type){
        case 'VIDEO_URLS':
            return { ...state, urls: action.urls }
        case 'GET_STATUS':
            return { ...state, status: action.status};
        default:
            break;
    }
    return state;
}

export default securityReducer;