import React, { useState } from 'react'
import { useSelector, connect } from 'react-redux'
import { createInventory } from '../../store/actions/inventoryActions'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom'
import CreatableSelect from 'react-select/creatable';
import KVRow from './commons/KVRow';

const EditInventory = (props) => {
  const navigate = useNavigate();
  const { id }  = useParams();
  const [inventory,setInventory] = useState(useSelector((state) => state.inventories[id]))
  const s = inventory.specifications ? Object.fromEntries(Object.keys(inventory.specifications).map((key, i) => ({ key, val: Object.values(inventory.specifications)[i]})).map((k,i)=>[i,k])): {}
  const [specId,setSpecId] = useState(Object.keys(s).length)
  const [specs,setSpecs] = useState(s)
  const deleteSpecRow = (i) => {
    setSpecs(() => {
      const newSpec = {...specs};
      delete newSpec[i]
      return newSpec;
    })
  }

  const tagOptions = Object.keys(props.tags).map((tag)=>{
    return {value:tag, label:tag}
  })

  const handleChange = (e) => {
    setInventory({ ...inventory,
      [e.target.id]: e.target.value
    })
  }

  const tagUpdated = (val) => {
    setInventory({ ...inventory,
      tags : val
    })
  }

  const saveSpec = () => {
    const updatedSpec = Object.fromEntries(Object.keys(specs).map(k => [specs[k].key,specs[k].val]))
    setInventory({
      ...inventory,
      specifications : updatedSpec
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    props.createInventory(inventory)
    navigate('/inventories')
  }

  const addSpec = () => {
    setSpecs({
      ...specs,
      [specId]:{
        key:'',
        val:''
      }
    })
    setSpecId(specId+1);
  }

  const renderSpecifications = Object.keys(specs).map((e,i) => {
    return(
      <KVRow key={e} index={e} setObj={setSpecs} obj={specs} deleteRow={deleteSpecRow}/>
    );
  })

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <h3 className='grey-text text-darken-3'>Edit Inventory</h3>
        <Form.Group as={Row} className="mb-3" controlId="invId">
          <Form.Label className='fw-bold' column sm="2">Inventory ID</Form.Label>
          <Col sm="10">
            <Form.Control plaintext readOnly onChange={handleChange} value={inventory.invId}/>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="name">
          <Form.Label className='fw-bold' column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control required onChange={handleChange} value={inventory.name} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="tags">
          <Form.Label className='fw-bold' column sm="2">
            Tags
          </Form.Label>
          <Col sm="10">
            <CreatableSelect isMulti value={inventory.tags} onChange={tagUpdated} options={tagOptions} />
          </Col>
        </Form.Group>
        <h4>Specifications</h4>
        <Row className='align-content-center'>
          <Col>Key</Col>
          <Col>Value</Col>
          <Col xs={1}></Col>
        </Row>
        <Row>
          <br></br>
        </Row>
        {renderSpecifications}
        <Form.Group as={Row}>
          <span className='btn btn-warning m-1' onClick={addSpec}>
            Add Specification
          </span>
        </Form.Group>
        {specId > 0 ? <Form.Group as={Row}>
          <span className='btn btn-primary m-1' onClick={saveSpec}>
            Save Specs
          </span>
        </Form.Group> : null }
        <Form.Group>
        <Button className='m-1' variant="warning" type="submit">
          UPDATE
        </Button>
        <Button className='m-1' variant="secondary" onClick={()=> navigate('/employees')}>
          CANCEL
        </Button>
      </Form.Group>
      </Form>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    createInventory: (inventory) => dispatch(createInventory(inventory))
  }
}

const mapStateToProps = (state) => {
  return state
} 

export default connect(mapStateToProps, mapDispatchToProps)(EditInventory);
