import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { Container, Form, Row, Col, Button, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLibrary } from '../../store/actions/lmsActions';
import { fetchGroups } from '../../store/actions/groupActions';

const GroupDetails = (props) => {

  useEffect(()=>{
    props.fetchGroups();
    props.getLibrary();
  },[])

  const { id } = useParams();
  const employees = props.groups && props.groups[id] && props.groups[id].employees;
  const videos = props.groups && props.groups[id] && props.groups[id].videos;
  const employeeDetails = props.employees;
  const videoDetails = props.lms.library;
  const group = useSelector((state) => state.groups[id]);
  const navigate = useNavigate();

  if(group){
    return (
      <Container>
        <Form>
        <h3 className='grey-text text-darken-3'>Group Details</h3>
        <Form.Group as={Row} className="mb-3" controlId="id">
          <Form.Label className='fw-bold' column sm="2">Group ID</Form.Label>
          <Col sm="10">
            <Form.Control plaintext readOnly defaultValue={group.id} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="name">
          <Form.Label className='fw-bold' column sm="2">Name</Form.Label>
          <Col sm="10">
            <Form.Control plaintext readOnly value={group.name}/>
          </Col>
        </Form.Group>
        <Row>
          <Col>
            <Container>
              <Row>
                <h3 className='grey-text text-darken-3'>Employees</h3>
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees && employeeDetails && employees.length > 0 && employees.map((eid,i)=>{
                      return(
                        <tr key={i}>
                          <td>{employeeDetails[eid.value].empId}</td>
                          <td>{employeeDetails[eid.value].firstName+' '+employeeDetails[eid.value].lastName}</td>
                          <td>
                            <NavLink className="btn btn-primary m-1 rounded-circle" to={`/employee/${eid.value}`}>
                              <i className="bi bi-eye"></i>
                            </NavLink>
                          </td>
                        </tr>
                      );
                    })
                    }
                  </tbody>
                </Table>
              </Row>
            </Container>
          </Col>
          <Col>
            <Container>
              <Row>
                <h3 className='grey-text text-darken-3'>Videos</h3>
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {videos && videoDetails && videos.length > 0 && videos.map((name,i)=>{
                      return(
                        <tr key={i}>
                          <td>{name.value}</td>
                          <td>{videoDetails[name.value]}</td>
                        </tr>
                      );
                    })
                    }
                  </tbody>
                </Table>
              </Row>
            </Container>
          </Col>
        </Row>
        <Button variant="secondary" onClick={()=> navigate('/groups')}>
          BACK
        </Button>
        </Form>
      </Container>
    )
  } else {
    return(
      <div className='container'>
        Loading....
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroups: () => dispatch(fetchGroups()),
    getLibrary: () => dispatch(getLibrary())
  }
}

const mapStateToProps = (state) => {
  return state
} 

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
