import React from "react";
import { connect } from "react-redux";
import ApproveLeave from './ApproveLeave'
import DeleteLeave from './DeleteLeave'


const LeaveSummary = ({employees, uid, leaveId, leave, removeLeave, approveLeave}) => {
  return(
    <tr className="align-middle">
      <td>
        {employees[uid].firstName} {employees[uid].lastName}
        <br />
        {employees[uid].email}
      </td>
      <td>{leave.begin}</td>
      <td>{leave.end}</td>
      <td>{leave.duration}</td>
      <td>{leave.month}</td>
      <td>{leave.reason}</td>
      <td>
        {leave.approved ? null : 
        <ApproveLeave leaveId={leaveId} uid={uid} leave={leave} approveLeave={approveLeave} />}
        <DeleteLeave leaveId={leaveId} uid={uid} leave={leave} removeLeave={removeLeave} />
      </td>
    </tr>
  );
}

const mapStateToProps = (state) => {
  return state;
}


export default connect(mapStateToProps)(LeaveSummary);