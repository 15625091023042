import React, { useState } from 'react'
import { connect } from 'react-redux'
import { createInventory } from '../../store/actions/inventoryActions'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import CreatableSelect from 'react-select/creatable';
import KVRow from './commons/KVRow';

const CreateInventory = (props) => {

  const navigate = useNavigate();
  const [inventory,setInventory] = useState({
    invId: '',
    tags: [],
    name: '',
    specifications: {}
  })
  const [specId,setSpecId] = useState(0)
  const [specs,setSpecs] = useState({})

  const deleteSpecRow = (i) => {
    setSpecs(() => {
      const newSpec = {...specs};
      delete newSpec[i]
      return newSpec;
    })
  }

  const tagOptions = Object.keys(props.tags).map((tag)=>{
    return {value:tag, label:tag}
  })

  const handleChange = (e) => {
    setInventory({ ...inventory,
      [e.target.id]: e.target.value
    })
  }

  const tagUpdated = (val) => {
    setInventory({ ...inventory,
      tags : val
    })
  }

  const reset = () => {
    setInventory({
      invId: '',
      tags: [],
      name: '',
      specifications: {}
    })
    setSpecs({})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    props.createInventory(inventory)
    setSpecId(0)
    reset();
  }

  const saveSpec = () => {
    const updatedSpec = Object.fromEntries(Object.keys(specs).map(k => [specs[k].key,specs[k].val]))
    setInventory({
      ...inventory,
      specifications : updatedSpec
    });
  }

  const addSpec = () => {
    setSpecs({
      ...specs,
      [specId]:{
        key:'',
        val:''
      }
    })
    setSpecId(specId+1);
  }

  const renderSpecifications = Object.keys(specs).map((e,i) => {
    return(
      <KVRow key={e} index={e} setObj={setSpecs} obj={specs} deleteRow={deleteSpecRow}/>
    );
  })

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <h3 className='grey-text text-darken-3'>Create Inventory</h3>
        <Form.Group as={Row} className="mb-3" controlId="invId">
          <Form.Label className='fw-bold' column sm="2">Inventory ID</Form.Label>
          <Col sm="10">
            <Form.Control required onChange={handleChange} value={inventory.invId}/>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="name">
          <Form.Label className='fw-bold' column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control required onChange={handleChange} value={inventory.name} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="tags">
          <Form.Label className='fw-bold' column sm="2">
            Tags
          </Form.Label>
          <Col sm="10">
            <CreatableSelect isMulti value={inventory.tags} onChange={tagUpdated} options={tagOptions} />
          </Col>
        </Form.Group>
        <h4>Specifications</h4>
        <Row className='align-content-center'>
          <Col>Key</Col>
          <Col>Value</Col>
          <Col xs={1}></Col>
        </Row>
        <Row>
          <br></br>
        </Row>
        {renderSpecifications}
        <Form.Group as={Row}>
          <span className='btn btn-warning m-1' onClick={addSpec}>
            Add Specification
          </span>
        </Form.Group>
        {specId>0 ? <Form.Group as={Row}>
          <span className='btn btn-primary m-1' onClick={saveSpec}>
            Save Specs
          </span>
        </Form.Group> : null }
        <Form.Group>
          <Button className='m-1' variant="success" type="submit">
            CREATE
          </Button>
          <Button className='m-1' variant="warning" onClick={()=> reset()}>
            RESET
          </Button>
          <Button className='m-1' variant="secondary" onClick={()=> navigate('/inventories')}>
            CANCEL
          </Button>
        </Form.Group>
      </Form>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    createInventory: (inventory) => dispatch(createInventory(inventory))
  }
}

const mapStateToProps = (state) => {
  return state
} 

export default connect(mapStateToProps, mapDispatchToProps)(CreateInventory);
