import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';

const DeleteLeave = ({ uid, leave, leaveId, removeLeave, employees }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return(
    <>
      <span className="btn btn-danger m-1 rounded-circle modal-trigger" onClick={handleShow}>
        <i className="bi bi-trash"></i>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Leave?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Employee: {employees[uid].firstName} {employees[uid].lastName}</p>
          <p>From: {leave.begin} To: {leave.end}</p>
          <p>Duration: {leave.duration}</p>
          <p>Month: {leave.month}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={()=> {removeLeave(uid,leaveId)}}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(DeleteLeave);