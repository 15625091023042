import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";

const VideoPage = (props) => {
  const [url,setUrl] = useState(props.url)
  const [progress,setProgress] = useState(0)
  useEffect(()=>{
    setUrl(props.url)
  },[props.count])
  const handleProgress = (event) => {
    const video = event.target;
    setProgress(video.currentTime * 100 / video.duration);
  }
  return(
    <Container>
      <h1>{props.title}</h1>
      <Col>
        <Row>
        <video key={url} height={400} controls controlsList="nodownload" onProgress={handleProgress}  >
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        </Row>
        <Row className="justify-content-center">
        {
          props.count<11 && progress>80?
            <Button className='m-1' variant="secondary" onClick={()=> props.increaseCount()}>
                Finish And Next
            </Button> : ''
        }
        </Row>
      </Col>
    </Container>);
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(VideoPage);