const initialState={}

export const employeeReducer = (state=initialState, action) => {
  switch(action.type){
    case 'GET_EMPLOYEE':
      return action.employee;
    case 'GET_EMPLOYEES':
      return action.employees;
    case 'REMOVE_EMPLOYEE':
      return state;
    case 'CLEAR_EMPLOYEES':
      return {};
    default:
      break;
  }
  return state;
}

export default employeeReducer;